import { MarkEmailRead } from '@mui/icons-material'
import {
  Button,
  Container,
  Fade,
  Paper,
  Typography,
  useTheme,
} from '@mui/material'
import { Box } from '@mui/system'
import { FirebaseError } from 'firebase/app'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import * as React from 'react'
import { signInUser } from '../../features/userSlice'
import { useAppDispatch } from '../../hooks'
import AppTextField from '../AppTextField'
import LoadingSpinner from '../LoadingSpinner'

const LoginPage = () => {
  const [isLinkSent, setIsLinkSent] = React.useState(false)
  const [isLoggingIn, setIsLoggingIn] = React.useState(false)
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [emailError, setEmailError] = React.useState('')
  const [passwordError, setPasswordError] = React.useState('')

  const theme = useTheme()
  const dispatch = useAppDispatch()

  // React.useMemo(() => {
  //   const auth = getAuth();
  //   if (isSignInWithEmailLink(auth, window.location.href)) {
  //     let email = localStorage.getItem("emailForSignIn");
  //     if (!email) {
  //       // User opened the link on a different device. To prevent session fixation
  //       // attacks, ask the user to provide the associated email again. For example:
  //       email = window.prompt("Please provide your email for confirmation.");
  //     }

  //     setPersistence(auth, browserSessionPersistence)
  //       .then(() =>
  //         signInWithEmailLink(auth, email ?? "", window.location.href)
  //       )
  //       .then((result) => dispatch(signInUser(result.user)))
  //       .catch((error) => {
  //         console.log(error);
  //         // Some error occurred, you can inspect the code: error.code
  //         // Common errors could be invalid email and invalid or expired OTPs.
  //       })
  //       .finally(() => {
  //         // Clear email from storage.
  //         localStorage.removeItem("emailForSignIn");
  //       });
  //   }
  // }, [dispatch]);

  const onSignInClicked = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (isLoggingIn) return

    if (!email.length) {
      setEmailError('Email cannot be empty.')
      return
    }

    if (!password.length) {
      setPasswordError('Password cannot be empty.')
      return
    }

    setEmailError('')
    setPasswordError('')

    const auth = getAuth()

    // const actionCodeSettings = {
    //   // URL you want to redirect back to. The domain (www.example.com) for this
    //   // URL must be in the authorized domains list in the Firebase Console.
    //   url: window.location.href,
    //   // This must be true.
    //   handleCodeInApp: true,
    //   dynamicLinkDomain: 'easyreport.page.link',
    // }

    setIsLoggingIn(true)

    signInWithEmailAndPassword(auth, email, password)
      .then((result) => dispatch(signInUser(result.user)))
      .catch((error) => {
        console.log(error)
        setIsLoggingIn(false)
        const firebaseError = error as FirebaseError
        switch (firebaseError.code) {
          case 'auth/wrong-password':
            setPasswordError('Password is incorrect.')
            break
          case 'auth/user-not-found':
            setEmailError("This email isn't registered.")
            break
          default:
            setEmailError('Unknown error occurred.')
            break
        }
      })
    // sendSignInLinkToEmail(auth, email, actionCodeSettings)
    //   .then(() => {
    //     setIsLinkSent(true)
    //     localStorage.setItem('emailForSignIn', email)
    //   })
    //   .catch((error: FirebaseError) => {
    //     console.log(error.stack, error.customData, error.name)
    //   })
    //   .finally(() => {
    //     setIsLoggingIn(false)
    //   })
  }

  const contentStyles = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    padding: '128px 96px',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  } as const

  const loginFormContent = (
    <Fade in={!isLinkSent} appear={false}>
      <Box
        component="form"
        sx={{ alignItems: 'start', ...contentStyles }}
        onSubmit={onSignInClicked}
      >
        <Typography fontSize="64px" fontWeight="700">
          Easy Report.
        </Typography>
        <Typography fontSize="16px">
          Reporting at ease. Simply enter your email and password to sign in.
        </Typography>
        <AppTextField
          value={email}
          onChange={(e) => {
            setEmail(e.target.value)
            setEmailError('')
          }}
          label="Email"
          disabled={isLoggingIn}
          error={!!emailError.length}
          helperText={emailError}
          sx={{
            m: '48px 0 12px',
            width: '100%',
          }}
        />
        <AppTextField
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          error={!!passwordError}
          helperText={passwordError}
          disabled={isLoggingIn}
          type="password"
          sx={{
            mb: '48px',
            width: '100%',
          }}
        />
        <Button
          variant="contained"
          sx={{ width: '100%', cursor: isLoggingIn ? 'unset' : 'pointer' }}
          disableRipple={isLoggingIn}
          type="submit"
        >
          {isLoggingIn ? (
            <LoadingSpinner
              size={16}
              ringColor={theme.palette.primary.contrastText}
            />
          ) : (
            'Sign in'
          )}
        </Button>
      </Box>
    </Fade>
  )

  const emailSentContent = (
    <Fade
      in={isLinkSent}
      style={{ transitionDelay: isLinkSent ? '1s' : undefined }}
    >
      <Box sx={{ alignItems: 'center', ...contentStyles }}>
        <MarkEmailRead
          sx={{ width: '64px', height: '64px', marginBottom: '24px' }}
        />
        <Typography fontSize="36px" fontWeight="700">
          Link sent!
        </Typography>
        <Typography textAlign="center">
          Check your inbox to continue.
        </Typography>
        <Button
          sx={{
            marginTop: '72px',
            textTransform: 'none',
          }}
          onClick={() => setIsLinkSent(false)}
        >
          Go back
        </Button>
      </Box>
    </Fade>
  )

  return (
    <Container maxWidth="lg">
      <Paper
        elevation={1}
        sx={{
          display: 'flex',
          margin: '5vh 0',
          borderRadius: '8px',
          height: '90vh',
          overflow: 'hidden',
        }}
      >
        <Box sx={{ display: 'flex', flex: 1, position: 'relative' }}>
          {loginFormContent}
          {emailSentContent}
        </Box>
        <Box
          sx={{
            flex: 1,
            background: `url("/images/login-landing-2.jpeg") center center/cover no-repeat`,
          }}
        />
      </Paper>
    </Container>
  )
}

export default LoginPage
