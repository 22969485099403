import { jsPDF } from 'jspdf'
import autoTable, {
  CellHookData,
  CellInput,
  RowInput,
  Styles,
} from 'jspdf-autotable'
import { v4 as uuidv4 } from 'uuid'
import Label from '../models/label'
import Report, {
  CategoryObject,
  HeaderObject,
  ItemObject,
  ReportData,
  ReportImage,
} from '../models/report'
import imageRepository from '../repositories/imageRepository'
import reportRepository from '../repositories/reportRepository'

interface ReportUsecase {
  listReports: (userID: string) => Promise<Report[]>
  getReportData: (reportID: string) => Promise<ReportData>
  createReport: (
    userID: string,
    labels: Label[],
    data: ReportData,
  ) => Promise<void>
  duplicateReport: (
    userID: string,
    labels: Label[],
    data: ReportData,
  ) => Promise<string>
  updateReport: (
    userID: string,
    reportID: string,
    labels: Label[] | undefined,
    data: ReportData,
  ) => Promise<void>
  updateReportItem: (
    reportID: string,
    data: ReportData,
    updatedItem: ItemObject,
  ) => Promise<void>
  archiveReport: (userID: string, reportID: string) => Promise<void>
  unarchiveReport: (userID: string, reportID: string) => Promise<void>
  generateReportPDF: ({ header, categories }: ReportData) => Promise<void>
}

const calculateCompletion = (data: ReportData) => {
  let itemCount = 0
  let completedItemCount = 0
  data.categories.forEach((category) => {
    itemCount += category.items.filter((item) => !item.isImageDisabled).length
    completedItemCount += category.items.filter((e) => e.value?.url).length
  })

  return Math.round((completedItemCount / itemCount) * 100)
}

const pathForImage = (reportID: string, file: File) => {
  const [, ext] = file.name.split('.')
  return `reports/${reportID}/${uuidv4()}.${ext}`
}

const trimNames = (data: ReportData) => {
  data.header.name = data.header.name.trim()
  data.categories.forEach((category) => {
    category.name = category.name.trim()
    category.items.forEach((item) => {
      item.name = item.name.trim()
    })
  })
}

const uploadImagesFromData = (reportID: string, data: ReportData) => {
  const dataToUpload = { ...data }
  const items = dataToUpload.categories.flatMap((category) => category.items)

  //upload images
  // const assignImageToObject = (object: HeaderObject | ItemObject) => (
  //   url: string,
  // ) => {
  //   switch (object.type) {
  //     case 'header':
  //       object.rightLogo = { url: url }
  //       break
  //     case 'item':
  //       object.value = { url: url }
  //       break
  //   }
  // }

  const uploadLogo = (position: 'left' | 'right', image?: ReportImage) => {
    const logoFile = image?.file
    if (!logoFile) {
      return new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve()
        }, 100)
      })
    }

    const promise = imageRepository
      .uploadImage(pathForImage(reportID, logoFile), logoFile)
      .then((url) => {
        switch (position) {
          case 'left':
            data.header.leftLogo = { url: url }
            break
          case 'right':
            data.header.rightLogo = { url: url }
            break
        }
      })

    const logoURLStr = image?.url
    if (!logoURLStr) {
      return promise
    }

    const logoURL = new URL(unescape(logoURLStr))
    console.log(logoURL, logoURL.host)
    if (logoURL.host === 'firebasestorage.googleapis.com') {
      const filename = logoURL.pathname.split('/').pop()

      return promise.then(() =>
        imageRepository.removeImage(`reports/${reportID}/${filename}`),
      )
    } else {
      return promise
    }
  }

  const initialValue = uploadLogo('left', data.header.leftLogo).then(() =>
    uploadLogo('right', data.header.rightLogo),
  )

  return items.reduce(async (prev, current) => {
    const file = current.value?.file
    if (file) {
      await prev
      return await imageRepository
        .uploadImage(pathForImage(reportID, file), file)
        .then((url) => {
          current.value = { url: url }
        })
    } else {
      return prev
    }
  }, initialValue)
}

const reportUsecase: ReportUsecase = {
  listReports: (userID: string) => {
    return reportRepository.listReports(userID)
  },

  getReportData: async (reportID: string) => {
    return reportRepository.fetchReportData(reportID)
  },

  createReport: async (userID: string, labels: Label[], data: ReportData) => {
    const reportID = reportRepository.getNewReportID()
    if (!reportID) {
      return new Promise<void>((_, reject) => {
        reject('Unable to generate report ID')
      })
    }

    trimNames(data)
    await uploadImagesFromData(reportID, data)
    await reportRepository.uploadReportData(reportID, data)
    return await reportRepository.uploadReport(userID, reportID, labels, data)
  },

  duplicateReport: async (
    userID: string,
    labels: Label[],
    data: ReportData,
  ) => {
    const reportID = reportRepository.getNewReportID()
    if (!reportID) {
      return new Promise<string>((_, reject) => {
        reject('Unable to generate report ID')
      })
    }

    trimNames(data)

    data.header.name = `Copy of ${data.header.name}`
    // clear images from report
    for (const category of data.categories) {
      for (const item of category.items) {
        delete item.value
      }
    }

    await reportRepository.uploadReportData(reportID, data)
    await reportRepository.uploadReport(userID, reportID, labels ?? [], data)

    return reportID
  },

  updateReport: async (
    userID: string,
    reportID: string,
    labels: Label[] | undefined,
    data: ReportData,
  ) => {
    trimNames(data)

    // erase image in item if image is disabled
    data.categories.forEach((category) => {
      category.items.forEach((item) => {
        if (item.isImageDisabled) {
          delete item.value
        }
      })
    })

    await uploadImagesFromData(reportID, data)

    const reportChanges: Partial<Report> = {
      title: data.header.name,
      completion: calculateCompletion(data),
      labels: labels,
    }

    return await reportRepository.updateReport(
      userID,
      reportID,
      reportChanges,
      data,
    )
  },

  updateReportItem: async (
    reportID: string,
    data: ReportData,
    updatedItem: ItemObject,
  ) => {
    let categoryIndex = -1
    let itemIndex = -1

    data.categories.forEach((category, i) => {
      category.items.forEach((item, j) => {
        if (item === updatedItem) {
          categoryIndex = i
          itemIndex = j
        }
      })
    })

    if (categoryIndex === -1 || itemIndex === -1) {
      throw new Error('error: updated item not found in report data')
    }

    updatedItem.name = updatedItem.name.trim()
    await uploadImagesFromData(reportID, data)

    await reportRepository.updateItem(
      reportID,
      categoryIndex,
      itemIndex,
      updatedItem,
    )

    await reportRepository.updateReport(data.ownerID, reportID, {}, data)
  },

  archiveReport: async (userID: string, reportID: string) => {
    await reportRepository.updateReport(
      userID,
      reportID,
      { isArchived: true },
      undefined,
    )
  },

  unarchiveReport: async (userID: string, reportID: string) => {
    await reportRepository.updateReport(
      userID,
      reportID,
      { isArchived: false },
      undefined,
    )
  },

  generateReportPDF: async ({ header, categories }: ReportData) => {
    const doc = new jsPDF()

    const tableSideMargin = 10

    const buildHeader = ({
      name,
      leftLogo,
      rightLogo,
      information,
    }: HeaderObject) => {
      return new Promise<void>((resolve, reject) => {
        const tableBody: CellInput[][] = []
        const columnCount = 2
        const maxLogoHeight = 20
        const maxLogoWidth = 35
        const titleFontSize = 20
        const cellWidth =
          (doc.internal.pageSize.width - tableSideMargin * 2) /
          (columnCount * 4)

        const headerStyles: Partial<Styles> = {
          halign: 'center',
          valign: 'middle',
          minCellHeight: maxLogoHeight + 10,
          fontSize: titleFontSize,
          cellWidth: cellWidth,
          cellPadding: { horizontal: 40, vertical: 10 },
          overflow: 'linebreak',
        }

        const splicedInfo = [['Site Name', header.siteName]]
        if (information && information.length) {
          splicedInfo.push(...information)
        }

        while (splicedInfo.length) {
          const rowItems = splicedInfo.splice(0, columnCount).map((e) => {
            const [name, value] = e
            if (!name) return []

            return [
              {
                content: `${name}:`,
                styles: { cellWidth: cellWidth, fontStyle: 'bold' },
              },
              {
                content: value,
                styles: { cellWidth: cellWidth * 3 },
              },
            ]
          })
          tableBody.push(Array.prototype.concat(...rowItems))
        }

        autoTable(doc, {
          head: [
            [
              {
                content: name,
                colSpan: 4,
                styles: headerStyles,
              },
            ],
          ],
          body: tableBody,
          bodyStyles: {
            halign: 'left',
          },
          margin: {
            horizontal: tableSideMargin,
            bottom: tableSideMargin,
          },
          theme: 'plain',
          didDrawCell: (data) => {
            if (data.section !== 'head') {
              return
            }

            const drawLogo = (url: string, position: 'left' | 'right') => {
              return new Promise<void>((imgResolve, imgReject) => {
                const img = new Image()
                img.onload = () => {
                  // const imgWidth = (maxLogoHeight / img.height) * img.width

                  let imgHeight = maxLogoHeight
                  let imgWidth = (imgHeight / img.height) * img.width
                  if (imgWidth > maxLogoWidth) {
                    imgWidth = maxLogoWidth
                    imgHeight = (imgWidth / img.width) * img.height
                  }
                  const yOffset =
                    data.cell.y + (data.cell.contentHeight - imgHeight) / 2
                  const xOffset =
                    position === 'left'
                      ? data.cell.x
                      : data.cell.x + data.cell.width - imgWidth

                  doc.addImage(
                    img,
                    'JPEG',
                    xOffset,
                    yOffset,
                    imgWidth,
                    imgHeight,
                  )

                  imgResolve()
                }
                img.onerror = () => {
                  console.error('Unable to load image to PDF.')
                  imgReject()
                }

                img.src = url
              })
            }
            const leftLogoURL = leftLogo?.url
            let leftLogoPromise: Promise<void>
            if (leftLogoURL) {
              leftLogoPromise = drawLogo(leftLogoURL, 'left')
            } else {
              leftLogoPromise = new Promise((resolve) => resolve())
            }

            const rightLogoURL = rightLogo?.url
            let rightLogoPromise: Promise<void>
            if (rightLogoURL) {
              rightLogoPromise = drawLogo(rightLogoURL, 'right')
            } else {
              rightLogoPromise = new Promise((resolve) => resolve())
            }

            leftLogoPromise
              .then(() => rightLogoPromise)
              .then(() => resolve())
              .catch((reason) => reject(reason))
          },
        })
      })
    }

    const nameFontSize = 12
    const descriptionFontSize = 10
    const imgBottomMargin = 4

    const buildTable = (
      { name, description, columnCount, items }: CategoryObject,
      isFirst: boolean,
    ) => {
      return new Promise<[ItemObject, CellHookData][]>((resolve) => {
        const tableBody: CellInput[][] = []
        const splicedItems = [...items]

        const cellPadding = 2
        const imgHeight = 45
        const nameHeight = doc.getTextDimensions('Text', {
          fontSize: nameFontSize,
        }).h
        const descriptionHeight = doc.getTextDimensions('Text', {
          fontSize: descriptionFontSize,
        }).h
        const cellWidth =
          (doc.internal.pageSize.width - tableSideMargin * 2) / columnCount

        const cellItemMap = new Map<number, ItemObject>()
        let cellCount = 0

        while (splicedItems.length) {
          const spliced = splicedItems.splice(0, columnCount)
          let hasDescription = false

          if (spliced.length < columnCount) {
            spliced.push({ name: '', type: 'item' })
          }

          spliced.every((e) => {
            if (e.description) {
              hasDescription = true
              return false
            }

            return true
          })

          tableBody.push(
            spliced.map((e) => ({
              content: e.name,
              colSpan: 1,
              styles: {
                halign: 'center',
                valign: 'bottom',
                cellPadding: hasDescription
                  ? {
                      top: cellPadding,
                      left: cellPadding,
                      right: cellPadding,
                      bottom: 0,
                    }
                  : cellPadding,
                cellWidth: cellWidth,
                fontSize: nameFontSize,
                minCellHeight:
                  imgHeight + nameHeight + cellPadding + imgBottomMargin,
                lineWidth: {
                  top: 0.1,
                  left: 0.1,
                  right: 0.1,
                  bottom: hasDescription ? 0 : 0.1,
                },
              },
            })),
          )

          if (hasDescription) {
            tableBody.push(
              spliced.map((e) => ({
                content: e.description ?? '',
                colSpan: 1,
                styles: {
                  minCellHeight: descriptionHeight,
                  fontStyle: 'italic',
                  fontSize: descriptionFontSize,
                  halign: 'center',
                  lineWidth: { top: 0, left: 0.1, right: 0.1, bottom: 0.1 },
                },
              })),
            )
          }

          const currentCellCount = cellCount
          spliced.forEach((item, i) => {
            cellItemMap.set(currentCellCount + i, item)
          })
          cellCount += columnCount * (hasDescription ? 2 : 1)
        }

        const totalImgCount = items.filter((e) => !!e.value?.url).length
        const cellDatas = [] as [ItemObject, CellHookData][]

        const footer: RowInput[] = [
          [
            {
              content: name,
              colSpan: columnCount,
              styles: {
                halign: 'center',
                fontSize: 14,
                fillColor: '#fff',
                textColor: '#000',
                lineWidth: {
                  top: 0.1,
                  left: 0.1,
                  right: 0.1,
                  bottom: description ? 0 : 0.1,
                },
                cellPadding: {
                  top: 4,
                  left: 4,
                  right: 4,
                  bottom: description ? 0 : 4,
                },
              },
            },
          ],
        ]

        if (description) {
          footer.push([
            {
              content: description,
              colSpan: columnCount,
              styles: {
                halign: 'center',
                fillColor: '#fff',
                fontSize: 12,
                fontStyle: 'italic',
                textColor: '#000',
                lineWidth: {
                  top: 0,
                  left: 0.1,
                  right: 0.1,
                  bottom: 0.1,
                },
                cellPadding: {
                  top: 0,
                  left: 4,
                  right: 4,
                  bottom: 4,
                },
              },
            },
          ])
        }

        autoTable(doc, {
          foot: footer,
          body: tableBody,
          margin: {
            top:
              doc.getCurrentPageInfo().pageNumber === 0 ? 15 : tableSideMargin,
            horizontal: tableSideMargin,
            bottom: tableSideMargin,
          },
          rowPageBreak: 'avoid',
          pageBreak: isFirst ? undefined : 'always',
          showHead: 'firstPage',
          showFoot: 'lastPage',
          theme: 'grid',
          didDrawCell: (data) => {
            if (data.section === 'body') {
              const cellIndex = data.column.index + data.row.index * columnCount
              const item = cellItemMap.get(cellIndex)

              if (item?.isImageDisabled) {
                const dimens = doc.getTextDimensions('N/A', { fontSize: 32 })
                const xPos = data.cell.x + (data.cell.width - dimens.w) / 2
                const yPos =
                  data.cell.y +
                  (data.cell.height - dimens.h) / 2 +
                  cellPadding * 2

                doc.setFontSize(32)
                doc.text('N/A', xPos, yPos)
                return
              }

              const { url: fileURL } = item?.value ?? {}

              if (!item || !fileURL) {
                if (cellDatas.length === totalImgCount) {
                  resolve(cellDatas)
                }
                return
              }

              cellDatas.push([item, data])
              if (cellDatas.length === totalImgCount) {
                resolve(cellDatas)
              }
            }
          },
        })
      })
    }

    // set timeout for async operations
    const timeout = <T>() => {
      return new Promise<T>((_, reject) => {
        setTimeout(() => reject('Timeout exceeded.'), 30000)
      })
    }

    await Promise.race([buildHeader(header), timeout()])

    const cellItemDatas = [] as [ItemObject, CellHookData][]
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i]
      console.log(category)
      const datas = await Promise.race([
        buildTable(category, i === 0),
        timeout<[ItemObject, CellHookData][]>(),
      ])
      cellItemDatas.push(...datas)
    }

    const drawImage = (item: ItemObject, data: CellHookData) => {
      return new Promise<void>((resolve, reject) => {
        const { url: fileURL } = item.value ?? {}

        if (!fileURL) {
          return
        }

        const img = new Image()
        img.src = fileURL

        img.onload = () => {
          let textDimens = doc.getTextDimensions(item.name, {
            fontSize: nameFontSize,
          })

          if (textDimens.w > data.cell.width) {
            textDimens = doc.getTextDimensions(item.name, {
              fontSize: nameFontSize,
              maxWidth: data.cell.width,
            })
          }

          const cellPadding = 2
          const maxImgWidth = data.cell.width - cellPadding * 2
          const maxImgHeight =
            data.cell.height - cellPadding - imgBottomMargin - textDimens.h

          let imgHeight = maxImgHeight
          let imgWidth = (imgHeight / img.height) * img.width
          let yOffset = data.cell.y + cellPadding
          if (imgWidth > maxImgWidth) {
            imgWidth = maxImgWidth
            imgHeight = (imgWidth / img.width) * img.height
            yOffset = data.cell.y + (maxImgHeight - imgHeight) / 2
          }
          const xOffset = data.cell.x + (data.cell.width - imgWidth) / 2

          doc.setPage((data.table.startPageNumber ?? 1) + data.pageNumber - 1)
          doc.addImage(img, 'JPEG', xOffset, yOffset, imgWidth, imgHeight)

          resolve()
        }

        img.onerror = () => {
          reject(`Unable to load image for ${item.name}`)
        }
      })
    }

    const initialPromise = new Promise<void>((resolve) => {
      resolve()
    })
    await cellItemDatas.reduce(
      (prev, [item, data]) => prev.then(() => drawImage(item, data)),
      initialPromise,
    )

    // add page number to pages
    for (let i = 1; i <= doc.getNumberOfPages(); i++) {
      doc.setPage(i)

      const fontSize = 12
      const text = `${i} of ${doc.getNumberOfPages()}`
      const dimens = doc.getTextDimensions(text, { fontSize: fontSize })
      const xPos = (doc.internal.pageSize.getWidth() - dimens.w) / 2
      const yPos = doc.internal.pageSize.getHeight() - dimens.h - 2

      doc.setFontSize(fontSize)
      doc.text(text, xPos, yPos)
    }

    doc.save(`${header.name} (${header.siteName}).pdf`)
  },
}

export const {
  listReports,
  createReport,
  duplicateReport,
  updateReport,
  updateReportItem,
  getReportData,
  generateReportPDF,
} = reportUsecase
export default reportUsecase
