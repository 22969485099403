import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCLQuys07GTsFW54Jy4ZfRgBLjXDzkmGZ4",
  authDomain: "easy-report-244c9.firebaseapp.com",
  databaseURL:
    "https://easy-report-244c9-default-rtdb.asia-southeast1.firebasedatabase.app/",
  projectId: "easy-report-244c9",
  storageBucket: "easy-report-244c9.appspot.com",
  messagingSenderId: "141114870039",
  appId: "1:141114870039:web:8958d6c4051fd59c944f52",
  measurementId: "G-BFDPDEJV1D",
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
export const auth = getAuth(firebaseApp);
export const database = getDatabase(firebaseApp);
export const storage = getStorage(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
