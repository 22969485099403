import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  EntityState,
} from '@reduxjs/toolkit'
import Report from '../models/report'
import { listReports } from '../usecases/reportUsecase'

interface State {
  status: 'idle' | 'fetching' | 'fetched' | 'error'
  error?: any
}

const reportsAdapter = createEntityAdapter<Report>({
  sortComparer: (a, b) => b.createdAt.localeCompare(a.createdAt),
})

const initialState = reportsAdapter.getInitialState<State>({
  status: 'idle',
  error: null,
})

export const fetchReports = createAsyncThunk<Report[], string>(
  'reports/fetch',
  async (userID) => {
    return await listReports(userID)
  },
)

const postSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    setReports: reportsAdapter.upsertMany,
    appendReport: reportsAdapter.addOne,
    modifyReport: reportsAdapter.updateOne,
    removeReport: reportsAdapter.removeOne,
    removeAllReports: reportsAdapter.removeAll,
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReports.pending, (state) => {
      state.status = 'fetching'
    })
    builder.addCase(fetchReports.fulfilled, (state, action) => {
      state.status = 'fetched'
      reportsAdapter.setAll(state, action.payload)
    })
    builder.addCase(fetchReports.rejected, (state, action) => {
      state.status = 'error'
      state.error = action.error
    })
  },
})

export const {
  setReports,
  appendReport,
  modifyReport,
  removeReport,
  removeAllReports,
} = postSlice.actions

// Export the customized selectors for this adapter using `getSelectors`
export const {
  selectAll: selectAllReports,
  selectById: selectReportByID,
  selectIds: selectReportIDs,
  // Pass in a selector that returns the posts slice of state
} = reportsAdapter.getSelectors<{ reports: EntityState<Report> & State }>(
  (state) => state.reports,
)

export const selectValidReports = (state: {
  reports: EntityState<Report> & State
}) => {
  const reports = selectAllReports(state)
  return reports.filter((e) => !e.isArchived)
}

export const selectArchivedReports = (state: {
  reports: EntityState<Report> & State
}) => {
  const reports = selectAllReports(state)
  return reports.filter((e) => !!e.isArchived)
}

export const selectAllLabels = (state: {
  reports: EntityState<Report> & State
}) => {
  const reports = selectAllReports(state)

  const labels = reports.flatMap((e) => e.labels).filter((e) => !!e)

  // remove duplicate labels
  const seen: { [key: string]: boolean } = {}
  return labels.filter((label) => {
    return seen.hasOwnProperty(label.name) ? false : (seen[label.name] = true)
  })
}

export default postSlice.reducer
