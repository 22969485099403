import { User } from "@firebase/auth";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { auth } from "../firebase";

export type CurrentUser = Omit<
  User,
  "delete" | "getIdToken" | "getIdTokenResult" | "reload" | "toJSON"
>;
type UserStatus = "not_loaded" | "pending" | "loaded";

interface UserState {
  currentUser: User | null;
  status: UserStatus;
}

const defaultValue = () => {
  return { currentUser: null, status: "pending" } as UserState;
};

// export const initializeUserState = createAsyncThunk<User>(
//   "user/initializeUserState",
//   async () => {
//     return await getFromIDBStore("currentUser");
//   }
// );

// export const setNewCurrentUser = createAsyncThunk<User, User>(
//   "user/setNewCurrentUser",
//   async (user) => {
//     // sanitize user
//     const sanitizedUser: Partial<User> = user;
//     delete sanitizedUser.delete;
//     delete sanitizedUser.getIdToken;
//     delete sanitizedUser.getIdTokenResult;
//     delete sanitizedUser.reload;
//     delete sanitizedUser.toJSON;
//     delete (sanitizedUser as any).auth;

//     await storeInIDBStore("currentUser", sanitizedUser as CurrentUser);

//     return user;
//   }
// );

export const signOut = createAsyncThunk("user/removeCurrentUser", async () => {
  // await removeFromIDBStore("currentUser");
  await auth.signOut();
});

const userSlice = createSlice({
  name: "user",
  initialState: defaultValue(),
  reducers: {
    initializeUserState: (state, action: PayloadAction<User | null>) => {
      state.currentUser = auth.currentUser;
      state.status = "loaded";
    },
    signInUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
      state.status = "loaded";
    },
  },
  extraReducers: (builder) => {
    // builder.addCase(initializeUserState.pending, (state) => {
    //   state.status = "pending";
    // });
    // builder.addCase(initializeUserState.fulfilled, (state, action) => {
    //   state.currentUser = action.payload;
    //   state.status = "loaded";
    // });
    // builder.addCase(initializeUserState.rejected, (state, action) => {
    //   if (action.error.message === IDBObjectNotFound) {
    //     state.status = "loaded";
    //   }
    // });
    // builder.addCase(setNewCurrentUser.pending, (state) => {
    //   state.status = "pending";
    // });
    // builder.addCase(setNewCurrentUser.fulfilled, (state, action) => {
    //   state.currentUser = action.payload;
    //   state.status = "loaded";
    // });
    // builder.addCase(setNewCurrentUser.rejected, (state, action) => {
    //   console.log(action.error);
    //   if (action.error.message === IDBObjectNotFound) {
    //     state.status = "loaded";
    //   }
    // });
    builder.addCase(signOut.fulfilled, (state) => {
      state.currentUser = null;
      state.status = "loaded";
    });
  },
});

export const { initializeUserState, signInUser } = userSlice.actions;

export default userSlice.reducer;
