import { getAuth } from "@firebase/auth";
import { createTheme, PaletteMode, ThemeOptions } from "@mui/material";
import Box from "@mui/material/Box";
import { amber, common, grey, indigo, teal } from "@mui/material/colors";
import { ThemeProvider } from "@mui/system";
import * as React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router";
import "./App.scss";
import CreateReportPage from "./components/pages/CreateReportPage";
import DashboardPage from "./components/pages/DashboardPage";
import LoginPage from "./components/pages/LoginPage";
import ReportLinkPage from "./components/pages/ReportLinkPage";
import ViewReportPage from "./components/pages/ViewReportPage";
import { initializeUserState } from "./features/userSlice";
import { useAppDispatch, useAppSelector } from "./hooks";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

type DesignTokens = (mode: PaletteMode) => ThemeOptions;
const getDesignTokens: DesignTokens = (mode: PaletteMode) => ({
  typography: {
    fontFamily: `"SF Pro", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
  palette: {
    mode,
    ...(mode === "light"
      ? {
          // palette values for light mode
          primary: {
            main: common.black,
          },
          secondary: amber,
          divider: grey[700],
          text: {
            primary: grey[900],
            secondary: grey[800],
          },
        }
      : {
          // palette values for dark mode
          primary: {
            main: grey[100],
          },
          secondary: {
            main: teal[700],
          },
          divider: indigo[700],
          background: {
            default: "#02044a",
            paper: indigo[900],
          },
          text: {
            primary: grey[100],
            secondary: "rgba(255,255,255, 0.7)",
          },
        }),
  },
  components: {
    MuiAccordion: {
      styleOverrides: {
        root: {
          "& .MuiTypography-root": {
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: "none",
          padding: "10px 16px",
          lineHeight: "normal",
        },
        outlined: {
          padding: "9px 15px",
        },
        startIcon: {
          marginRight: "auto",
        },
        endIcon: {
          marginLeft: "auto",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "0.875rem",
        },
      },
    },
  },
});

export default function App() {
  const [mode, setMode] = React.useState<PaletteMode>("light");
  const colorMode = React.useMemo(
    () => ({
      // The dark mode switch would invoke this method
      toggleColorMode: () => {
        setMode((prevMode: PaletteMode) =>
          prevMode === "light" ? "dark" : "light"
        );
      },
    }),
    []
  );

  const dispatch = useAppDispatch();
  const location = useLocation();

  const currentUser = useAppSelector((state) => state.users.currentUser);
  const userStatus = useAppSelector((state) => state.users.status);

  getAuth().onAuthStateChanged((user) => {
    dispatch(initializeUserState(user));
  });

  // Update the theme only if the mode changes
  const theme = React.useMemo(() => createTheme(getDesignTokens(mode)), [mode]);

  if (!location.pathname.startsWith("/l/")) {
    if (!currentUser && userStatus === "pending") {
      return <></>;
    }

    if (
      !currentUser &&
      userStatus === "loaded" &&
      location.pathname !== "/login"
    ) {
      return <Navigate to="/login" />;
    }
    if (
      currentUser &&
      userStatus === "loaded" &&
      location.pathname === "/login"
    ) {
      return <Navigate to="/" />;
    }
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <Box
          sx={{
            display: "flex",
            width: "100vw",
            height: "100%",
            bgcolor: "background.default",
            color: "text.primary",
          }}
        >
          {/* <IconButton
                sx={{ ml: 1 }}
                onClick={colorMode.toggleColorMode}
                color="inherit"
              >
                {theme.palette.mode === "dark" ? (
                  <Brightness7Rounded />
                ) : (
                  <Brightness4Rounded />
                )}
              </IconButton> */}
          <Routes>
            <Route path="/" element={<DashboardPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/reports/new" element={<CreateReportPage />} />
            <Route path="/reports/:id" element={<ViewReportPage />} />
            <Route path="/reports/:id/edit" element={<CreateReportPage />} />
            <Route path="/l/:id" element={<ReportLinkPage />} />
          </Routes>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
