import { Select, SelectProps, Typography } from "@mui/material";
import { Box, styled, SxProps, Theme } from "@mui/system";

const StyledSelect = styled(Select)({
  "& .MuiSelect-select": {
    padding: "10px 12px",
    fontSize: "0.875rem",
    minHeight: "unset",
    lineHeight: "normal",
  },
});

interface Props extends Omit<SelectProps, "variant" | "sx"> {
  sx?: SxProps<Theme>;
}

const AppSelect = (props: Props) => {
  const { label, sx, ...selectProps } = props;

  return (
    <Box sx={sx}>
      <Typography fontSize="14px" fontWeight="600">
        {label}
      </Typography>
      <StyledSelect
        sx={{ width: "100%", marginTop: "4px" }}
        {...selectProps}
        variant="outlined"
      />
    </Box>
  );
};

export default AppSelect;
