import { OutlinedTextFieldProps, TextField, Typography } from "@mui/material";
import { Box, styled, SxProps, Theme } from "@mui/system";
import * as React from "react";

const StyledTextField = styled(TextField)({
  "& input": {
    padding: "8px 12px",
    fontSize: "0.875rem",
  },
});

interface Props extends Omit<OutlinedTextFieldProps, "variant" | "sx"> {
  sx?: SxProps<Theme>;
}

const AppTextField = (props: Props) => {
  const { label, sx, ...textFieldProps } = props;

  return (
    <Box sx={sx}>
      <Typography fontSize="14px" fontWeight="600">
        {label}
      </Typography>
      <StyledTextField
        sx={{ width: "100%", marginTop: "4px" }}
        {...textFieldProps}
        InputLabelProps={{ shrink: false }}
        variant="outlined"
      />
    </Box>
  );
};

export default AppTextField;
