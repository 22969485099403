import {
  ArrowBack,
  ArrowBackIos,
  ArrowForward,
  ArrowForwardIos,
  Cameraswitch,
  Close,
  Collections,
  Done,
  FlashOff,
  FlashOn,
  ImageNotSupported,
  PhotoCamera,
  Repeat,
  RotateLeft,
  RotateRight,
  TaskAlt,
} from '@mui/icons-material'
import {
  Alert,
  Button,
  Divider,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { v4 as uuidv4 } from 'uuid'
import { CategoryObject, ItemObject, ReportData } from '../../models/report'
import { getReportData, updateReportItem } from '../../usecases/reportUsecase'

interface InitialPage {
  content: 'initial'
}

interface ItemPage {
  categoryIndex: number
  itemIndex: number
  content: 'item'
  cameraImage?: TempImage
}
interface CameraPage {
  content: 'camera'
  prevPage: Page
}

type TempImage = [
  File,
  string,
  Date | undefined,
  GeolocationCoordinates | undefined,
]
type Page = InitialPage | ItemPage | CameraPage

type UploadResult = 'success' | 'error'

const ReportLinkPage = () => {
  const params = useParams()
  const { id: reportID } = params

  const [data, setData] = React.useState<ReportData>()
  const [page, setPage] = React.useState<Page>({ content: 'initial' })

  React.useEffect(() => {
    if (reportID && !data) {
      getReportData(reportID).then((reportData) => setData(reportData))
    }
  }, [reportID, data])

  if (!reportID || !data) return <></>

  let content: JSX.Element

  switch (page.content) {
    case 'initial':
      content = (
        <InitialPageContent
          data={data}
          onContinue={() =>
            setPage({
              categoryIndex: 0,
              itemIndex: 0,
              content: 'item',
            })
          }
        />
      )
      break
    case 'item':
      content = (
        <ItemPageContent
          reportID={reportID}
          page={page}
          setPage={setPage}
          data={data}
          setData={setData}
        />
      )
      break
    case 'camera':
      content = (
        <CameraContent
          onClose={() => {
            setPage(page.prevPage)
          }}
          onDone={(image) => {
            ;(page.prevPage as ItemPage).cameraImage = image
            setPage(page.prevPage)
          }}
        />
      )
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {content}
      <Typography
        fontFamily="Orbitron"
        sx={{ position: 'absolute', opacity: 0 }}
      >
        Orbitron
      </Typography>
    </Box>
  )
}

const CategoryNavigation = ({
  data,
  categoryIndex,
  isUploading,
  onPrev,
  onNext,
  onTitleClick,
}: {
  data: ReportData
  categoryIndex: number
  isUploading: boolean
  onPrev: () => void
  onNext: () => void
  onTitleClick: () => void
}) => {
  const category = data.categories[categoryIndex]
  const isPrevCategoryDisabled = isUploading || categoryIndex === 0
  const isNextCategoryDisabled =
    isUploading || categoryIndex === data.categories.length - 1

  return (
    <Box
      sx={{
        width: '100%',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'primary.main',
      }}
    >
      <IconButton
        disabled={isPrevCategoryDisabled}
        sx={{ opacity: isPrevCategoryDisabled ? 0.4 : 1 }}
        onClick={onPrev}
      >
        <ArrowBack sx={{ color: 'primary.contrastText' }} />
      </IconButton>
      <Box
        onClick={onTitleClick}
        sx={{
          flex: 1,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
      >
        <Typography
          color="primary.contrastText"
          fontSize="12px"
          sx={{ opacity: 0.8 }}
        >
          Kategori
        </Typography>
        <Typography color="primary.contrastText" sx={{ textAlign: 'center' }}>
          {category.name}
        </Typography>
      </Box>
      <IconButton
        disabled={isNextCategoryDisabled}
        sx={{ opacity: isNextCategoryDisabled ? 0.4 : 1 }}
        onClick={onNext}
      >
        <ArrowForward sx={{ color: 'primary.contrastText' }} />
      </IconButton>
    </Box>
  )
}

const CategoryList = ({
  data,
  selectedCategory,
  onSelect,
  onClose,
}: {
  data: ReportData
  selectedCategory: number
  onSelect: (index: number) => void
  onClose: () => void
}) => {
  const remainingUploads = (category: CategoryObject) =>
    category.items.filter((e) => !e.value?.url).length
  const remainingUploadsText = (category: CategoryObject) => {
    const remainingUploadsCount = remainingUploads(category)
    if (!remainingUploadsCount) return 'Semua foto sudah dimasukkan'

    return `Kurang ${remainingUploadsCount} foto lagi`
  }

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
          padding: '8px 16px',
        }}
      >
        <Typography fontWeight="600" fontSize="14px" sx={{ flex: 1 }}>
          Semua kategori
        </Typography>
        <Button
          variant="outlined"
          size="small"
          sx={{ padding: '6px 12px' }}
          onClick={onClose}
        >
          Tutup
        </Button>
      </Box>
      <List sx={{ width: '100%', overflow: 'scroll', paddingTop: 0 }}>
        {data.categories.map((category, i) => (
          <React.Fragment key={i}>
            <ListItem
              selected={selectedCategory === i}
              onClick={() => onSelect(i)}
              disablePadding
            >
              <ListItemButton>
                <ListItemText
                  primary={category.name}
                  secondary={remainingUploadsText(category)}
                />
                {remainingUploads(category) === 0 && (
                  <ListItemAvatar>
                    <TaskAlt color="success" />
                  </ListItemAvatar>
                )}
              </ListItemButton>
            </ListItem>
            <Divider light />
          </React.Fragment>
        ))}
      </List>
    </>
  )
}

const InitialPageContent = ({
  data,
  onContinue,
}: {
  data: ReportData
  onContinue: () => void
}) => {
  return (
    <Box
      sx={{
        padding: '16px',
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Stack direction="row" sx={{ mb: '8px' }}>
        {data.header.leftLogo?.url && (
          <Box
            component="img"
            sx={{
              width: 'auto',
              height: 'auto',
              maxWidth: '50px',
              maxHeight: '50px',
              objectFit: 'cover',
            }}
            src={data.header.leftLogo?.url}
          />
        )}
        {data.header.rightLogo?.url && (
          <Box
            component="img"
            sx={{
              width: 'auto',
              height: 'auto',
              maxWidth: '50px',
              maxHeight: '50px',
              objectFit: 'cover',
              ml: data.header.leftLogo ? 2 : 0,
            }}
            src={data.header.rightLogo?.url}
          />
        )}
      </Stack>
      <Typography fontSize="24px" fontWeight="700">
        {data.header.name}
      </Typography>
      <Box
        sx={{
          width: '100%',
          maxWidth: '640px',
          padding: '8px',
          boxSizing: 'border-box',
          marginTop: '16px',
          border: '1px solid',
          borderColor: 'primary.main',
          borderRadius: '12px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box sx={{ display: 'flex', width: '100%' }}>
          <Typography
            fontSize="14px"
            fontWeight="500"
            sx={{ marginRight: '8px', flex: 3 }}
          >
            Site Name
          </Typography>
          <Typography fontSize="14px" fontWeight="400" sx={{ flex: 7 }}>
            : {data.header.siteName}
          </Typography>
        </Box>
        {data.header.information.map((info, i) => (
          <Box key={i} sx={{ display: 'flex', width: '100%' }}>
            <Typography
              fontSize="14px"
              fontWeight="500"
              sx={{ marginRight: '8px', flex: 3 }}
            >
              {info[0]}
            </Typography>
            <Typography fontSize="14px" fontWeight="400" sx={{ flex: 7 }}>
              : {info[1]}
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        onClick={onContinue}
        sx={{ marginTop: '32px' }}
      >
        Mulai upload
      </Button>
    </Box>
  )
}

const ItemPageContent = ({
  reportID,
  page,
  setPage,
  data,
  setData,
}: {
  reportID: string
  page: ItemPage
  setPage: React.Dispatch<React.SetStateAction<Page>>
  data: ReportData
  setData: React.Dispatch<React.SetStateAction<ReportData | undefined>>
}) => {
  const [tempImage, setTempImage] = React.useState(page.cameraImage)
  const [isUploading, setIsUploading] = React.useState(false)
  const [isCategoryListOpen, setIsCategoryListOpen] = React.useState(false)
  const [failReason, setFailReason] = React.useState('')
  const [failedUploads, setFailedUploads] = React.useState<ItemObject[]>([])
  const [uploadResult, setUploadResult] = React.useState<UploadResult>()

  const cameraInputRef = React.useRef<HTMLInputElement>()
  const canvasRef = React.useRef<HTMLCanvasElement>()
  const backingCanvasRef = React.useRef<HTMLCanvasElement>()

  const showFailReason = false

  React.useEffect(() => {
    if (!data) return

    const { categoryIndex, itemIndex } = page
    const item = data.categories[categoryIndex].items[itemIndex]

    if (item.value?.file && failedUploads.find((e) => e === item)) {
      setUploadResult('error')
    }
  }, [failedUploads, data, page])

  const { categoryIndex, itemIndex } = page
  const category = data.categories[categoryIndex]
  const item = category.items[itemIndex]

  const overallIndex = data.categories
    .slice(0, categoryIndex)
    .reduce((prev, current) => prev + current.items.length, itemIndex + 1)
  const totalUploadedItems = data.categories.reduce(
    (prev, current) =>
      prev + current.items.filter((e) => !!e.value?.url).length,
    0,
  )
  const totalItems = data.categories.reduce(
    (prev, current) => prev + current.items.length,
    0,
  )

  const isPrevItemDisabled =
    isUploading || (itemIndex === 0 && categoryIndex === 0)
  const isNextItemDisabled =
    isUploading ||
    (itemIndex === category.items.length - 1 &&
      page.categoryIndex === data.categories.length - 1)

  useEffect(() => {
    page.cameraImage = undefined
  }, [page])

  const navigateCategories = (direction: 'prev' | 'next') => () => {
    setUploadResult(undefined)

    switch (direction) {
      case 'prev':
        if (page.categoryIndex > 0) {
          page.categoryIndex -= 1
          page.itemIndex = 0
          setPage({ ...page })
        }
        break
      case 'next':
        if (page.categoryIndex < data.categories.length - 1) {
          page.categoryIndex += 1
          page.itemIndex = 0
          setPage({ ...page })
        }
        break
    }
  }

  const navigateItems = (direction: 'prev' | 'next') => () => {
    setUploadResult(undefined)

    switch (direction) {
      case 'prev':
        if (page.itemIndex > 0) {
          page.itemIndex -= 1
          setPage({ ...page })
        } else if (page.categoryIndex > 0) {
          page.categoryIndex -= 1
          page.itemIndex = data.categories[page.categoryIndex].items.length - 1
          setPage({ ...page })
        }
        break
      case 'next':
        const category = data.categories[page.categoryIndex]
        if (page.itemIndex < category.items.length - 1) {
          page.itemIndex += 1
          setPage({ ...page })
        } else if (page.categoryIndex < data.categories.length - 1) {
          page.categoryIndex += 1
          page.itemIndex = 0
          setPage({ ...page })
        }
        break
    }
  }

  const updateItem = (item: ItemObject) => {
    const newData = { ...data }
    setData(newData)

    setFailedUploads(failedUploads.filter((e) => e !== item))
    setUploadResult(undefined)
    setIsUploading(true)

    updateReportItem(reportID, newData, item)
      .then(() => {
        setUploadResult('success')
        setTempImage(undefined)
        setTimeout(() => setUploadResult(undefined), 3000)
      })
      .catch((err) => {
        console.log(err)
        setFailReason(`${err}`)
        setFailedUploads([...failedUploads, item])
      })
      .finally(() => setIsUploading(false))
  }

  const drawToCanvas = async (
    canvas: HTMLCanvasElement,
    image: HTMLImageElement,
    tempImage: TempImage,
  ) => {
    const ctx = canvas.getContext('2d')
    if (!ctx) throw new Error('Cannot obtain context from canvas')

    const [, , date, coordinates] = tempImage

    const width = canvas.width
    const height = canvas.height
    const fontSize = Math.round(width / 45)

    ctx.drawImage(image, 0, 0, width, height)

    ctx.font = `500 ${fontSize}px Orbitron`
    ctx.shadowColor = 'black'
    ctx.shadowBlur = 2
    ctx.fillStyle = '#FDD835'

    // const buff = await imageFile.arrayBuffer()
    // const tags = ExifReader.load(buff)

    const latitude = coordinates?.latitude //?? tags.GPSLatitude?.description
    const longitude = coordinates?.longitude //?? tags.GPSLongitude?.description
    const dateTime = date?.toLocaleString() //?? tags.DateTime?.description

    if (!latitude && !longitude && !dateTime) {
      return
    }

    canvas.height += fontSize * 4.5

    ctx.fillText(data.header.siteName, fontSize, height + fontSize * 1.5)

    if (dateTime) {
      // const measurement = ctx.measureText(dateTime);
      // const x = width - measurement.width - fontSize;
      const x = fontSize
      const y = height + fontSize * 2.5

      ctx.fillText(dateTime, x, y)
    }

    if (latitude && longitude) {
      const location = `${latitude}, ${longitude}`
      // const measurement = ctx.measureText(location);
      // const x = width - measurement.width - fontSize;
      const x = fontSize
      const y = height + fontSize * 3.5

      ctx.fillText(location, x, y)
    }
  }

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFile = event.target.files && event.target.files[0]

    if (!newFile) {
      return
    }

    const fileURL = URL.createObjectURL(newFile)
    setTempImage([newFile, fileURL, undefined, undefined])
  }

  const onImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    if (!tempImage) return

    const [, fileURL] = tempImage
    const canvas = canvasRef.current
    const backingCanvas = backingCanvasRef.current
    if (!canvas || !backingCanvas) return

    const maxWidth = 2000
    const maxHeight = 2000

    const displayedImg = e.target as HTMLImageElement

    const image = new Image()
    image.onload = () => {
      let width = image.width
      let height = image.height

      // calculate the width and height, constraining the proportions
      if (width > height) {
        if (width > maxWidth) {
          height = Math.round((height *= maxWidth / width))
          width = maxWidth
        }
      } else {
        if (height > maxHeight) {
          width = Math.round((width *= maxHeight / height))
          height = maxHeight
        }
      }

      // resize the canvases and draw the image data onto them
      backingCanvas.width = width
      backingCanvas.height = height
      canvas.width = displayedImg.width
      canvas.height = displayedImg.height

      drawToCanvas(canvas, image, tempImage)
      drawToCanvas(backingCanvas, image, tempImage).then(() => {
        backingCanvas.toBlob(
          (blob) => {
            if (!blob) return

            // const fileURL = URL.createObjectURL(blob);
            const filename = item.value?.file?.name ?? `image-${itemIndex}.jpg`

            item.value = {
              file: new File([blob], filename, { type: 'image/jpeg' }),
              // file: file,
              localURL: item.value?.localURL,
              url: item.value?.url,
            }

            updateItem(item)
          },
          'image/jpeg',
          0.8,
        )
      })
    }
    image.src = fileURL
  }

  let imgContent: JSX.Element
  if (tempImage) {
    const [, tempURL] = tempImage
    imgContent = (
      <Box>
        <Box
          component="img"
          src={tempURL}
          onLoad={onImageLoad}
          sx={{
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: 0,
          }}
        />
        <Box
          component="canvas"
          ref={canvasRef}
          sx={{
            position: 'absolute',
            maxWidth: '100%',
            maxHeight: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: uploadResult ? 0.6 : 1,
            bgcolor: '#000000',
          }}
        />
        <Box
          component="canvas"
          ref={backingCanvasRef}
          sx={{
            position: 'absolute',
            display: 'none',
          }}
        />
      </Box>
    )
  } else if (item.value?.localURL || item.value?.url) {
    imgContent = (
      <Box>
        <Box
          component="img"
          src={item.value?.localURL ?? item.value?.url}
          sx={{
            position: 'absolute',
            width: 'auto',
            height: 'auto',
            maxWidth: '100%',
            maxHeight: '100%',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            opacity: 1,
          }}
        />
      </Box>
    )
  } else {
    imgContent = (
      <Box
        sx={{
          position: 'absolute',
          width: 'auto',
          height: 'auto',
          maxWidth: '100%',
          maxHeight: '100%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ImageNotSupported
          sx={{
            width: '36px',
            height: '36px',
            marginBottom: '16px',
          }}
        />
        <Typography>Belum ada foto</Typography>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Typography
        fontSize="14px"
        fontWeight="500"
        sx={{
          padding: '8px 0',
          backgroundColor: 'grey.200',
          width: '100%',
          textAlign: 'center',
        }}
      >
        Proyek: {data.header.name}
      </Typography>
      <CategoryNavigation
        isUploading={isUploading}
        data={data}
        categoryIndex={categoryIndex}
        onPrev={navigateCategories('prev')}
        onNext={navigateCategories('next')}
        onTitleClick={() => setIsCategoryListOpen(!isCategoryListOpen)}
      />
      {isCategoryListOpen && (
        <CategoryList
          data={data}
          selectedCategory={categoryIndex}
          onSelect={(i) => {
            setIsCategoryListOpen(false)

            if (i === page.categoryIndex) return

            page.categoryIndex = i
            page.itemIndex = 0
            setPage({ ...page })
          }}
          onClose={() => setIsCategoryListOpen(false)}
        />
      )}
      {!isCategoryListOpen && (
        <>
          <Alert
            severity={totalUploadedItems === totalItems ? 'success' : 'info'}
            sx={{ margin: '8px', width: 'calc(100% - 16px)' }}
          >
            {totalUploadedItems === totalItems ? (
              'Semua foto telah berhasil dimasukkan.'
            ) : (
              <>
                Masih ada total{' '}
                <strong>{totalItems - totalUploadedItems} foto</strong> yang
                belum dimasukkan.
              </>
            )}
          </Alert>
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              margin: '0 8px 32px',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'grey.200',
                position: 'relative',
                width: '100%',
              }}
            >
              {imgContent}
              {isUploading && (
                <LinearProgress
                  sx={{
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    position: 'absolute',
                  }}
                />
              )}
              {uploadResult === 'success' && (
                <Alert severity="success" sx={{ zIndex: 6 }}>
                  Berhasil memasukkan foto.
                </Alert>
              )}
              {uploadResult === 'error' && (
                <Alert
                  severity="error"
                  sx={{
                    zIndex: 6,
                    '& .MuiAlert-message': {
                      marginRight: '8px',
                    },
                    '& .MuiAlert-action': {
                      padding: 0,
                    },
                  }}
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={() => updateItem(item)}
                    >
                      Coba lagi
                    </Button>
                  }
                >
                  {showFailReason ? failReason : 'Gagal memasukkan foto ini.'}
                </Alert>
              )}
            </Box>
          </Box>
          <Typography fontSize="16px" color="text.secondary">
            {`${overallIndex}/${totalItems}`}
          </Typography>
          <Typography
            fontWeight="500"
            fontSize="18px"
            sx={{ textAlign: 'center', mt: 0.5 }}
          >
            {item.name}
          </Typography>
          <Box
            ref={cameraInputRef}
            component="input"
            accept="image/*;capture=camera"
            type="file"
            sx={{ display: 'none' }}
            onChange={onFileChange}
          />
          <Button
            variant="contained"
            // onClick={() => cameraInputRef.current?.click()}
            onClick={() => setPage({ content: 'camera', prevPage: page })}
            disabled={isUploading}
            endIcon={<PhotoCamera />}
            sx={{
              width: '60%',
              maxWidth: '240px',
              mt: 2,
            }}
          >
            {item.value?.url
              ? 'Ubah foto dari kamera'
              : 'Masukkan foto dari kamera'}
          </Button>
          <Button
            variant="outlined"
            onClick={() => cameraInputRef.current?.click()}
            disabled={isUploading}
            endIcon={<Collections />}
            sx={{
              width: '60%',
              maxWidth: '240px',
              m: '8px 0 48px',
            }}
          >
            {item.value?.url
              ? 'Ubah foto dari galeri'
              : 'Masukkan foto dari galeri'}
          </Button>
          <Box
            sx={{
              display: 'flex',
              padding: '8px',
              width: '100%',
              boxSizing: 'border-box',
            }}
          >
            <Button
              variant="outlined"
              sx={{ flex: 1, marginRight: '8px' }}
              onClick={navigateItems('prev')}
              disabled={isPrevItemDisabled}
              startIcon={
                <ArrowBackIos sx={{ width: '14px', height: '14px' }} />
              }
            >
              Sebelumnya
            </Button>
            <Button
              variant="contained"
              sx={{ flex: 1 }}
              onClick={navigateItems('next')}
              disabled={isNextItemDisabled}
              endIcon={
                <ArrowForwardIos sx={{ width: '14px', height: '14px' }} />
              }
            >
              Berikutnya
            </Button>
          </Box>
        </>
      )}
      {/* {tags && <Box sx={{ maxWidth: "100%" }}>{tags}</Box>} */}
    </Box>
  )
}

const CameraContent = ({
  onClose,
  onDone,
}: {
  onClose: () => void
  onDone: (image: TempImage) => void
}) => {
  const [cameraIDs, setCameraIDs] = useState<string[]>([])
  const [currentCameraID, setCurrentCameraID] = useState('')
  const [isFlashOn, setIsFlashOn] = useState(false)
  const [image, setImage] = React.useState<TempImage>()
  const [isGettingCoords, setIsGettingCoords] = React.useState(false)
  const [isFrontFacing, setIsFrontFacing] = React.useState(false)

  const videoRef = React.useRef<HTMLVideoElement>()
  const imageRef = React.useRef<HTMLImageElement>()
  const canvasRef = React.useRef<HTMLCanvasElement>()
  const isRotating = React.useRef(false)

  React.useEffect(() => {
    if (image) {
      const video = videoRef.current
      if (!video || !video.srcObject) return
      ;(video.srcObject as MediaStream)
        .getVideoTracks()
        .forEach((track) => track.stop())
      return
    }

    // check feature
    const hasGetUserMedia = !!(
      navigator.mediaDevices && navigator.mediaDevices.getUserMedia
    )
    if (!hasGetUserMedia) {
      alert('getUserMedia() is not supported by your browser')
      return
    }

    navigator.mediaDevices
      .getUserMedia({
        video: {
          width: { min: 1280 },
          height: { min: 720 },
        },
      })
      .then(() => {
        navigator.mediaDevices.enumerateDevices().then((deviceInfos) => {
          const filtered = deviceInfos.filter((e) => e.kind === 'videoinput')
          setCameraIDs(filtered.map((e) => e.deviceId))
        })
      })
  }, [image])

  React.useEffect(() => {
    if (!cameraIDs.length) return

    const video = videoRef.current
    if (!video) return

    // last is usually back-facing camera
    const cameraID = cameraIDs[cameraIDs.length - 1]
    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: { exact: cameraID },
          width: { min: 1280 },
          height: { min: 720 },
          aspectRatio: { ideal: 4 / 3 },
        },
      })
      .then((stream) => {
        video.srcObject = stream
        setCurrentCameraID(cameraID)

        const facingMode = stream.getVideoTracks()[0].getCapabilities()
          .facingMode
        if (facingMode?.includes('user')) {
          setIsFrontFacing(true)
        } else {
          setIsFrontFacing(false)
        }
      })
  }, [cameraIDs])

  React.useEffect(() => {
    const video = videoRef.current
    if (!video) return

    return () => {
      if (!video.srcObject) return
      ;(video.srcObject as MediaStream)
        .getVideoTracks()
        .forEach((track) => track.stop())
    }
  }, [])

  const onSwitchCamera = () => {
    const index = cameraIDs.indexOf(currentCameraID)
    if (index === -1 || cameraIDs.length === 1) return

    const video = videoRef.current
    if (!video) return

    const newIndex = (index + 1) % cameraIDs.length
    const cameraID = cameraIDs[newIndex]

    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: { exact: cameraID },
          width: { min: 1280 },
          height: { min: 720 },
          aspectRatio: { ideal: 4 / 3 },
        },
      })
      .then((stream) => {
        video.srcObject = stream
        setCurrentCameraID(cameraID)

        const facingMode = stream.getVideoTracks()[0].getCapabilities()
          .facingMode
        if (facingMode?.includes('user')) {
          setIsFrontFacing(true)
        } else {
          setIsFrontFacing(false)
        }
      })
  }

  const onToggleFlash = () => {
    const video = videoRef.current
    if (!video) return

    setIsFlashOn(!isFlashOn)
  }

  const onCapture = () => {
    const canvas = canvasRef.current
    const video = videoRef.current
    if (!canvas || !video) return

    canvas.width = video.videoWidth
    canvas.height = video.videoHeight

    const ctx = canvas.getContext('2d')
    if (!ctx) {
      alert('Unable to create context.')
      return
    }

    ctx.drawImage(video, 0, 0)
    canvas.toBlob(
      (blob) => {
        if (!blob) return

        const filename = `${uuidv4()}.jpg`
        const file = new File([blob], filename, { type: 'image/jpeg' })
        const filepath = URL.createObjectURL(file)

        setImage([file, filepath, new Date(), undefined])
      },
      'image/jpeg',
      0.8,
    )
  }

  const rotateImage = (direction: 'left' | 'right') => () => {
    if (isRotating.current) return

    const canvas = canvasRef.current
    const image = imageRef.current
    const ctx = canvas?.getContext('2d')

    if (!canvas || !ctx || !image) {
      alert('Unable to create context.')
      return
    }

    isRotating.current = true

    ctx.clearRect(0, 0, canvas.width, canvas.height)

    const currentWidth = canvas.width
    const currentHeight = canvas.height
    canvas.width = currentHeight
    canvas.height = currentWidth

    ctx.save()

    // move to the center of the canvas
    ctx.translate(canvas.width / 2, canvas.height / 2)
    ctx.rotate((Math.PI / 2) * (direction === 'left' ? -1 : 1))
    ctx.drawImage(image, -canvas.height / 2, -canvas.width / 2)
    ctx.restore()

    canvas.toBlob(
      (blob) => {
        isRotating.current = false

        if (!blob) return

        const filename = `${uuidv4()}.jpg`
        const file = new File([blob], filename, { type: 'image/jpeg' })
        const filepath = URL.createObjectURL(file)

        setImage([file, filepath, new Date(), undefined])
      },
      'image/jpeg',
      0.8,
    )
  }

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        bgcolor: '#000000',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <Box component="canvas" ref={canvasRef} sx={{ display: 'none' }} />
      {image && (
        <>
          <Box
            sx={{
              flex: 1,
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
              width: '100%',
            }}
          >
            <Box
              ref={imageRef}
              component="img"
              sx={{
                position: 'absolute',
                width: 'auto',
                height: 'auto',
                maxWidth: '100%',
                maxHeight: '100%',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
              src={image[1]}
            />
          </Box>
          <Box
            sx={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              mt: 2,
              mb: 2,
            }}
          >
            <Stack
              justifyContent="center"
              alignItems="center"
              onClick={rotateImage('left')}
              sx={{ minWidth: '120px', mr: 6 }}
            >
              <RotateLeft
                sx={{
                  color: 'primary.contrastText',
                  width: '32px',
                  height: '32px',
                  mb: 0.5,
                }}
              />
              <Typography color="primary.contrastText" fontSize="14px">
                Putar kiri
              </Typography>
            </Stack>
            <Stack
              justifyContent="center"
              alignItems="center"
              onClick={rotateImage('right')}
              sx={{ minWidth: '120px' }}
            >
              <RotateRight
                sx={{
                  color: 'primary.contrastText',
                  width: '32px',
                  height: '32px',
                  mb: 0.5,
                }}
              />
              <Typography color="primary.contrastText" fontSize="14px">
                Putar kanan
              </Typography>
            </Stack>
          </Box>
          <Box
            sx={{
              display: 'flex',
              p: 1,
              width: '100%',
              boxSizing: 'border-box',
              bgcolor: 'background.default',
            }}
          >
            <Button
              variant="outlined"
              sx={{ flex: 1, mr: 1 }}
              onClick={() => setImage(undefined)}
              startIcon={<Repeat sx={{ width: '14px', height: '14px' }} />}
              disabled={isGettingCoords}
            >
              Ambil ulang
            </Button>
            <Button
              variant="contained"
              sx={{ flex: 1 }}
              onClick={() => {
                setIsGettingCoords(true)

                navigator.geolocation.getCurrentPosition(
                  (pos) => {
                    const imageWithCoords = image
                    imageWithCoords[3] = pos.coords
                    onDone(imageWithCoords)
                  },
                  () => {
                    onDone(image)
                  },
                )
              }}
              endIcon={<Done sx={{ width: '14px', height: '14px' }} />}
              disabled={isGettingCoords}
            >
              Selesai
            </Button>
          </Box>
        </>
      )}
      {!image && (
        <>
          <Box
            component="video"
            ref={videoRef}
            sx={{
              width: '100%',
              height: '100%',
              top: 0,
              left: 0,
              transform: isFrontFacing ? 'scaleX(-1)' : 'none',
            }}
            autoPlay
            playsInline
          />
          <IconButton
            onClick={onSwitchCamera}
            sx={{
              position: 'absolute',
              left: 'calc(50% - 128px)',
              bottom: '40px',
              bgcolor: 'grey.900',
              p: 1.5,
              '&:hover': {
                bgcolor: 'grey.400',
              },
            }}
          >
            <Cameraswitch
              sx={{ color: '#fff', width: '24px', height: '24px' }}
            />
          </IconButton>
          <Box
            onClick={onCapture}
            sx={{
              p: 0.25,
              cursor: 'pointer',
              position: 'absolute',
              borderRadius: '50%',
              border: '2px solid #ffffff',
              left: '50%',
              bottom: '32px',
              transform: 'translateX(-50%)',
              boxShadow: '0px 1px 4px rgb(0 0 0 / 44%)',
            }}
          >
            <Box
              sx={{
                width: '64px',
                height: '64px',
                bgcolor: '#ffffff',
                borderRadius: '50%',
                boxShadow: '0px 1px 4px rgb(0 0 0 / 44%)',
              }}
            />
          </Box>
          <IconButton
            onClick={onToggleFlash}
            sx={{
              display: 'none',
              // flash not supported
              position: 'absolute',
              right: 'calc(50% - 128px)',
              bottom: '40px',
              bgcolor: 'grey.900',
              p: 1.5,
              '&:hover': {
                bgcolor: 'grey.400',
              },
            }}
          >
            {isFlashOn ? (
              <FlashOn sx={{ color: '#fff', width: '24px', height: '24px' }} />
            ) : (
              <FlashOff sx={{ color: '#fff', width: '24px', height: '24px' }} />
            )}
          </IconButton>
        </>
      )}
      <IconButton
        sx={{
          position: 'absolute',
          right: '16px',
          top: '16px',
        }}
        onClick={onClose}
      >
        <Close sx={{ color: '#fff' }} />
      </IconButton>
    </Box>
  )
}

export default ReportLinkPage
