import {
  Archive,
  ArrowBack,
  ArrowDownward,
  ArrowUpward,
  ExpandLess,
  ExpandMore,
  NoteAdd,
} from '@mui/icons-material'
import {
  Autocomplete,
  Button,
  Chip,
  Container,
  Divider,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slide,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import Box from '@mui/material/Box'
import { styled } from '@mui/styles'
import { BoxProps, SxProps } from '@mui/system'
import * as React from 'react'
import { useNavigate } from 'react-router-dom'
import {
  fetchReports,
  modifyReport,
  removeAllReports,
  selectAllLabels,
  selectArchivedReports,
  selectValidReports,
} from '../../features/reportSlice'
import { signOut } from '../../features/userSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Label from '../../models/label'
import Report from '../../models/report'
import { observeReportChanges } from '../../store'
import reportUsecase from '../../usecases/reportUsecase'
import LoadingSpinner from '../LoadingSpinner'

type SortOrder =
  | 'dateCreatedDesc'
  | 'dateCreatedAsc'
  | 'nameDesc'
  | 'nameAsc'
  | 'progressDesc'
  | 'progressAsc'

const SortMenuItem = styled(MenuItem)({
  minWidth: '160px',
  '& svg': {
    marginLeft: 'auto',
    width: 16,
    height: 16,
  },
})

const DashboardPage = () => {
  const [page, setPage] = React.useState<'valid' | 'archived'>('valid')
  const [currentTime, setCurrentTime] = React.useState(new Date())
  const [openedReport, setOpenedReport] = React.useState<string | undefined>()
  const [selectedLabels, setSelectedLabels] = React.useState<string[]>([])
  const [sortOrder, setSortOrder] = React.useState<SortOrder>('dateCreatedDesc')
  const currentUser = useAppSelector((state) => state.users.currentUser)
  const reports = useAppSelector(
    page === 'valid' ? selectValidReports : selectArchivedReports,
  )
  const reportsStatus = useAppSelector((state) => state.reports.status)
  const labels = useAppSelector(selectAllLabels)

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  let sortedReports = [...reports]
  switch (sortOrder) {
    case 'dateCreatedDesc':
      // this is the default order from the state
      break
    case 'dateCreatedAsc':
      sortedReports.sort((a, b) => a.createdAt.localeCompare(b.createdAt))
      break
    case 'nameAsc':
      sortedReports.sort((a, b) => a.title.localeCompare(b.title))
      break
    case 'nameDesc':
      sortedReports.sort((a, b) => b.title.localeCompare(a.title))
      break
    case 'progressAsc':
      sortedReports.sort((a, b) => (a.completion > b.completion ? 1 : -1))
      break
    case 'progressDesc':
      sortedReports.sort((a, b) => (b.completion > a.completion ? 1 : -1))
      break
  }

  const displayedReports =
    sortedReports && selectedLabels.length
      ? sortedReports.filter(
          (report) =>
            report.labels &&
            report.labels.filter((label) => selectedLabels.includes(label.name))
              .length,
        )
      : sortedReports

  React.useEffect(() => {
    if (!currentUser) return

    switch (reportsStatus) {
      case 'idle':
        dispatch(fetchReports(currentUser.uid))
        break
      case 'fetched':
        const unsubscribes = observeReportChanges(currentUser.uid)
        return () => unsubscribes.forEach((e) => e())
    }
  }, [currentUser, reportsStatus, dispatch])

  React.useEffect(() => {
    const clockTimer = setInterval(() => {
      setCurrentTime(new Date())
    }, 60000)

    return () => clearInterval(clockTimer)
  })

  const onFilterChange = (e: React.SyntheticEvent, value: Label[]) => {
    if (!value) return
    setSelectedLabels(value.map((e) => e.name))
  }

  const onSortOrderChange = (e: SelectChangeEvent<string>) => {
    switch (e.target.value) {
      case 'name':
        setSortOrder('nameAsc')
        break
      case 'dateCreated':
        setSortOrder('dateCreatedDesc')
        break
      case 'progress':
        setSortOrder('progressAsc')
        break
      default:
        break
    }
  }
  const onSortOptionClick = (
    option: 'dateCreated' | 'name' | 'progress',
  ) => () => {
    switch (option) {
      case 'dateCreated':
        if (sortOrder.startsWith('dateCreated'))
          setSortOrder(
            sortOrder.endsWith('Desc') ? 'dateCreatedAsc' : 'dateCreatedDesc',
          )
        break
      case 'name':
        if (sortOrder.startsWith('name'))
          setSortOrder(sortOrder.endsWith('Desc') ? 'nameAsc' : 'nameDesc')
        break
      case 'progress':
        if (sortOrder.startsWith('progress'))
          setSortOrder(
            sortOrder.endsWith('Desc') ? 'progressAsc' : 'progressDesc',
          )
        break
    }
  }

  const onArchiveStatusChange = (report: Report) => (
    action: 'archive' | 'unarchive',
  ) => {
    if (!report.id || !currentUser) return

    dispatch(
      modifyReport({
        id: report.id,
        changes: { isArchived: action === 'archive' },
      }),
    )
    setOpenedReport(undefined)

    action === 'archive'
      ? reportUsecase.archiveReport(currentUser.uid, report.id)
      : reportUsecase.unarchiveReport(currentUser.uid, report.id)
  }

  const currentTimeInString = () => {
    const hours = currentTime.getHours()
    const minutes = currentTime.getMinutes()

    let minutesString = minutes.toString()
    minutesString =
      minutesString.length === 1 ? `0${minutesString}` : minutesString

    return `${hours}.${minutesString}`
  }

  let content: JSX.Element

  switch (reportsStatus) {
    case 'fetching':
      content = <LoadingSpinner size={48} sx={{ alignSelf: 'center' }} />
      break
    case 'fetched':
      content = (
        <>
          {page === 'valid' && (
            <Button
              variant="outlined"
              disableElevation
              onClick={() => navigate('/reports/new')}
              endIcon={<NoteAdd />}
              sx={{
                mb: '24px',
                padding: '24px',
                width: '100%',
                textTransform: 'none',
                borderRadius: '12px',
                borderWidth: '2px',
                textAlign: 'start',
                fontWeight: '500',
                fontSize: '18px',
                justifyContent: 'start',
                '&:hover': {
                  borderWidth: '2px',
                  borderColor: 'primary.main',
                },
              }}
            >
              Create new
            </Button>
          )}
          {displayedReports.map((report, i) => (
            <ReportButton
              report={report}
              key={report.id}
              isOpen={openedReport === report.id}
              onClick={() => setOpenedReport(report.id)}
              onClose={() => setOpenedReport(undefined)}
              changeArchiveStatus={onArchiveStatusChange(report)}
              sx={{
                mt: i > 0 ? 2 : 0,
                mb:
                  page === 'archived' && i === displayedReports.length - 1
                    ? 15
                    : 0,
              }}
            />
          ))}
          {page === 'valid' && (
            <Button
              size="large"
              variant="outlined"
              startIcon={<Archive />}
              sx={{
                alignSelf: 'center',
                mt: 4,
                mb: 15,
                '& .MuiButton-startIcon': { mr: 1 },
              }}
              onClick={() => setPage('archived')}
            >
              View archived
            </Button>
          )}
          {page === 'archived' && reports.length === 0 && (
            <Typography
              fontSize="20px"
              sx={{ alignSelf: 'center', mt: 10, mb: 10 }}
            >
              You have no archived reports.
            </Typography>
          )}
        </>
      )
      break
    default:
      content = <></>
      break
  }

  return (
    <Box
      sx={{
        width: '100vw',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '36px',
        }}
      >
        <Box
          sx={{
            fontFamily: 'Arvo',
            fontSize: '24px',
            position: 'relative',
            whiteSpace: 'pre-line',
          }}
        >
          <Box sx={{ fontWeight: 700 }} component="span">
            E
          </Box>
          asy{'\n'}
          <Box sx={{ fontWeight: 700 }} component="span">
            R
          </Box>
          eport
          <Box
            sx={{
              position: 'absolute',
              width: '16px',
              height: '16px',
              backgroundColor: 'secondary.main',
              borderRadius: '50%',
              right: '-24px',
              bottom: '-8px',
            }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography fontWeight="700" fontSize="24px">
            {currentTimeInString()}
          </Typography>
          <Typography fontSize="14px" color="text.secondary">
            {currentTime.toLocaleDateString()}
          </Typography>
        </Box>
        <Button
          variant="outlined"
          onClick={() => {
            dispatch(signOut())
            dispatch(removeAllReports())
          }}
        >
          Sign out
        </Button>
      </Box>
      <Divider sx={{ width: '100%', mb: 6 }} />
      <Container maxWidth="sm">
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            alignItems: 'start',
            flexDirection: 'column',
          }}
        >
          <Stack direction="row" alignItems="center" sx={{ mb: 6 }}>
            {page === 'archived' && (
              <IconButton
                color="primary"
                sx={{ mr: 2 }}
                onClick={() => setPage('valid')}
              >
                <ArrowBack />
              </IconButton>
            )}
            <Typography fontSize="36px" fontWeight="700">
              {page === 'valid' ? 'Your Reports' : 'Archived Reports'}
            </Typography>
          </Stack>
          <Stack direction="row" sx={{ width: '100%', mb: 3 }} spacing={2}>
            <Autocomplete
              multiple
              options={labels}
              getOptionLabel={(option) => option.name}
              onChange={onFilterChange}
              sx={{ flex: 1 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Filter"
                />
              )}
            />
            <Select
              variant="standard"
              value={sortOrder.replaceAll(/(?:Asc)|(?:Desc)/g, '')}
              onChange={onSortOrderChange}
              label="Age"
              sx={{
                minWidth: 160,
                '& .MuiSelect-standard': {
                  display: 'flex',
                  alignItems: 'center',
                },
                '& svg': { width: 16, height: 16, ml: 'auto' },
              }}
            >
              <SortMenuItem value="name" onClick={onSortOptionClick('name')}>
                Name{sortOrder === 'nameAsc' && <ArrowUpward />}
                {sortOrder === 'nameDesc' && <ArrowDownward />}
              </SortMenuItem>
              <SortMenuItem
                value="dateCreated"
                onClick={onSortOptionClick('dateCreated')}
              >
                Date Created{sortOrder === 'dateCreatedAsc' && <ArrowUpward />}
                {sortOrder === 'dateCreatedDesc' && <ArrowDownward />}
              </SortMenuItem>
              <SortMenuItem
                value="progress"
                onClick={onSortOptionClick('progress')}
              >
                Progress{sortOrder === 'progressAsc' && <ArrowUpward />}
                {sortOrder === 'progressDesc' && <ArrowDownward />}
              </SortMenuItem>
            </Select>
          </Stack>
          {content}
        </Box>
      </Container>
    </Box>
  )
}

interface ReportButtonProps extends BoxProps {
  report: Report
  isOpen: boolean
  onClick: () => void
  onClose: () => void
  changeArchiveStatus: (action: 'archive' | 'unarchive') => void
}

const ReportButton = ({
  report,
  isOpen,
  onClick,
  onClose,
  changeArchiveStatus,
  sx,
  ...props
}: ReportButtonProps) => {
  const [isArchived, setIsArchived] = React.useState(report.isArchived)
  const containerRef = React.useRef<HTMLDivElement>()
  const navigate = useNavigate()

  const archiveTimeout = React.useRef<NodeJS.Timeout>()

  const buttonStyles: SxProps = {
    padding: '6px 10px',
    fontSize: '12px',
  }

  React.useEffect(() => {
    if (isArchived && report.isArchived) return
    if (!isArchived && !report.isArchived) return

    if (archiveTimeout.current) clearTimeout(archiveTimeout.current)

    if (isArchived)
      archiveTimeout.current = setTimeout(
        () => changeArchiveStatus('archive'),
        1500,
      )
    else changeArchiveStatus('unarchive')
  }, [isArchived, report.isArchived, changeArchiveStatus])

  return (
    <Box
      {...props}
      sx={{
        position: 'relative',
        width: '100%',
        borderRadius: '12px',
        overflow: 'hidden',
        ...sx,
      }}
      ref={containerRef}
    >
      <Button
        variant="contained"
        disableElevation
        onClick={onClick}
        sx={{
          padding: '24px',
          width: '100%',
          textTransform: 'none',
          borderRadius: '12px',
          textAlign: 'start',
          fontWeight: '500',
          fontSize: '18px',
          justifyContent: 'start',
          position: 'relative',
        }}
      >
        <ExpandMore sx={{ marginRight: '8px' }} />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
          }}
        >
          <span>{report.title}</span>
          {report.labels && (
            <Stack direction="row" spacing={1} sx={{ mt: 1 }}>
              {report.labels.map((label, i) => (
                <Chip
                  label={`#${label.name}`}
                  color="secondary"
                  key={i}
                  variant="outlined"
                  size="small"
                />
              ))}
            </Stack>
          )}
        </Box>
        <Box
          sx={{
            position: 'absolute',
            right: '24px',
            fontWeight: '300',
            fontSize: '28px',
          }}
        >
          {report.completion}%
        </Box>
      </Button>
      <Slide
        in={isOpen}
        direction="down"
        appear={false}
        container={containerRef.current}
      >
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'grey.200',
            width: '100%',
            height: '100%',
            padding: '24px 24px 24px 16px',
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={onClose}>
            <ExpandLess color="primary" />
          </IconButton>
          <Typography sx={{ flex: 1 }} fontWeight="500" fontSize="18px">
            {report.title}
          </Typography>
          {report.isArchived && (
            <Button
              variant="outlined"
              sx={buttonStyles}
              color="warning"
              onClick={() => setIsArchived(false)}
            >
              Unarchive
            </Button>
          )}
          {!report.isArchived && (
            <>
              <Button
                variant="outlined"
                sx={{ marginRight: '8px', ...buttonStyles }}
                onClick={() => navigate(`/reports/${report.id}`)}
              >
                View
              </Button>
              <Button
                variant="outlined"
                sx={{ marginRight: '8px', ...buttonStyles }}
                onClick={() => navigate(`/reports/${report.id}/edit`)}
              >
                Edit
              </Button>
              <Button
                variant="outlined"
                sx={buttonStyles}
                color="warning"
                onClick={() => setIsArchived(true)}
              >
                Archive
              </Button>
            </>
          )}
        </Box>
      </Slide>
      {isArchived && !report.isArchived && (
        <Stack
          sx={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            bgcolor: 'error.main',
            color: 'primary.contrastText',
            p: 3,
            top: 0,
          }}
          alignItems="start"
          justifyContent="center"
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ width: '100%' }}
          >
            <Typography fontWeight="700" sx={{ flex: 1 }}>
              Archived.
            </Typography>
            <Button
              variant="outlined"
              sx={{
                ...buttonStyles,
                color: 'error.contrastText',
                borderColor: 'error.contrastText',
                '&:hover': { borderColor: 'error.contrastText' },
              }}
              onClick={() => setIsArchived(false)}
            >
              Undo
            </Button>
          </Stack>
        </Stack>
      )}
    </Box>
  )
}

export default DashboardPage
