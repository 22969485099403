import { Done, ImageNotSupported, TaskAlt } from '@mui/icons-material'
import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  Divider,
  LinearProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Snackbar,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { alpha, Box, SxProps } from '@mui/system'
import * as React from 'react'
import { useNavigate, useParams } from 'react-router'
import { fetchReports, selectReportByID } from '../../features/reportSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { CategoryObject, ItemObject, ReportData } from '../../models/report'
import { observeReportDataChanges } from '../../repositories/reportRepository'
import { observeReportChanges } from '../../store'
import {
  duplicateReport,
  generateReportPDF,
  getReportData,
  updateReport,
} from '../../usecases/reportUsecase'
import CircularProgressWithLabel from '../CircularProgressWithLabel'

const calculateCompletion = (category: CategoryObject) => {
  const completed = category.items.filter((item) => item.value?.url).length
  const total = category.items.filter((item) => !item.isImageDisabled).length
  return Math.round((completed / total) * 100)
}

const calculateOverallCompletion = (data: ReportData) => {
  const total = data.categories.reduce(
    (prev, current) =>
      prev + current.items.filter((item) => !item.isImageDisabled).length,
    0,
  )
  const completed = data.categories.reduce(
    (prev, current) =>
      prev + current.items.filter((item) => item.value?.url).length,
    0,
  )
  return Math.round((completed / total) * 100)
}

const ViewReportPage = () => {
  const params = useParams()
  const { id: reportID } = params

  const [data, setData] = React.useState<ReportData>()
  const [updatingItem, setUpdatingItem] = React.useState<ItemObject>()
  const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState(0)
  const [isSnackbarOpen, setIsSnackbarOpen] = React.useState(false)
  const [isDuplicatingReport, setIsDuplicatingReport] = React.useState(false)
  const [isGeneratingPDF, setIsGeneratingPDF] = React.useState(false)
  const reportsStatus = useAppSelector((state) => state.reports.status)

  const currentUser = useAppSelector((state) => state.users.currentUser)
  const report = useAppSelector((state) =>
    selectReportByID(state, reportID ?? ''),
  )

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (reportID && !data) {
      getReportData(reportID).then((reportData) => setData(reportData))
    }

    if (reportID) {
      const unsubscribe = observeReportDataChanges(reportID, (newData) => {
        if (!data || !currentUser) return
        setData({
          ownerID: newData.ownerID ?? currentUser.uid,
          header: newData.header ?? data.header,
          categories: newData.categories ?? data.categories,
        })
      })
      return unsubscribe
    }
  }, [reportID, data, currentUser])

  React.useEffect(() => {
    if (!currentUser) return

    switch (reportsStatus) {
      case 'idle':
        dispatch(fetchReports(currentUser.uid))
        break
      case 'fetched':
        const unsubscribes = observeReportChanges(currentUser.uid)
        return () => unsubscribes.forEach((e) => e())
    }
  }, [currentUser, reportsStatus, dispatch])

  if (!reportID || !report || !data || !currentUser) return <></>

  const selectedCategory = data.categories[selectedCategoryIndex]

  const clickDuplicate = () => {
    setIsDuplicatingReport(true)
    duplicateReport(currentUser.uid, report.labels, data)
      .then((newReportID) => {
        navigate(`/reports/${newReportID}`)
      })
      .finally(() => setIsDuplicatingReport(false))
  }

  const clickDownloadPDF = () => {
    setIsGeneratingPDF(true)
    generateReportPDF(data).finally(() => setIsGeneratingPDF(false))
  }

  return (
    <Box sx={{ width: '100vw', display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          width: '100%',
          p: 4,
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Box
          component="a"
          href="/"
          sx={{
            mr: 3,
          }}
        >
          <Box
            component="img"
            src="/logo512.png"
            sx={{
              width: '48px',
              borderRadius: '6px',
              boxShadow: '0px 2px 4px rgba(0,0,0,0.4)',
            }}
            onClick={() => {
              navigate('/')
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'start',
            flex: 1,
          }}
        >
          <Typography
            component="h1"
            fontSize="20px"
            fontWeight="500"
            sx={{ flex: 1 }}
          >
            {data.header.name}
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            fontWeight="400"
            sx={{ flex: 1 }}
          >
            {calculateOverallCompletion(data)}% completed
          </Typography>
        </Box>
        <Button
          variant="outlined"
          sx={{ marginRight: '16px' }}
          onClick={() => {
            navigate(`/reports/${reportID}/edit`)
          }}
          disabled={isDuplicatingReport}
        >
          Edit
        </Button>
        <Button
          variant="outlined"
          sx={{ marginRight: '16px' }}
          onClick={clickDuplicate}
          disabled={isDuplicatingReport}
        >
          Duplicate
        </Button>
        <Button
          variant="outlined"
          sx={{ marginRight: '16px' }}
          onClick={() => {
            const url = new URL(window.location.href)
            navigator.clipboard.writeText(`${url.host}/l/${reportID}`)
            setIsSnackbarOpen(true)
          }}
        >
          Copy upload link
        </Button>
        <Button
          variant="contained"
          onClick={clickDownloadPDF}
          disabled={isDuplicatingReport}
        >
          Download PDF
        </Button>
      </Box>
      {report.labels && (
        <Stack direction="row" spacing={1} sx={{ px: 4, pb: 4 }}>
          {report.labels.map((label, i) => (
            <Chip
              label={`#${label.name}`}
              color="secondary"
              key={i}
              size="small"
            />
          ))}
        </Stack>
      )}
      <Divider light />
      <Box sx={{ p: 4, bgcolor: 'grey.200', flex: 1 }}>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            bgcolor: 'background.paper',
            minHeight: '100%',
          }}
        >
          <Box
            sx={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              borderRight: '1px solid',
              borderColor: 'grey.400',
            }}
          >
            <List component="nav">
              {data.categories.map((category, i) => {
                const completion = calculateCompletion(category)
                return (
                  <ListItemButton
                    key={i}
                    selected={selectedCategoryIndex === i}
                    onClick={() => setSelectedCategoryIndex(i)}
                  >
                    <ListItemText
                      primary={category.name}
                      secondary={`${
                        category.items.length === 1
                          ? `${category.items.length} item`
                          : `${category.items.length} items`
                      }, ${completion}% completed`}
                    />
                    {completion === 100 && (
                      <ListItemIcon>
                        <TaskAlt color="success" />
                      </ListItemIcon>
                    )}
                  </ListItemButton>
                )
              })}
            </List>
          </Box>
          <Box sx={{ flex: 2, padding: '32px' }}>
            <CategoryPage
              category={selectedCategory}
              updatingItem={updatingItem}
              onItemChange={(item) => {
                const newData = { ...data }
                setUpdatingItem(item)
                setData(newData)
                updateReport(
                  currentUser.uid,
                  reportID,
                  undefined,
                  newData,
                ).finally(() => setUpdatingItem(undefined))
              }}
            />
          </Box>
        </Paper>
      </Box>
      <Snackbar
        open={isSnackbarOpen}
        autoHideDuration={6000}
        onClose={() => setIsSnackbarOpen(false)}
        message="Copied to clipboard."
      />
      <Dialog open={isGeneratingPDF}>
        {/* <DialogTitle>Please Wait</DialogTitle> */}
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress sx={{ mb: '24px' }} />
          <DialogContentText>
            Generating PDF file for '{report.title}'…
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </Box>
  )
}

const CategoryPage = ({
  category,
  updatingItem,
  onItemChange,
}: {
  category: CategoryObject
  updatingItem?: ItemObject
  onItemChange: (item: ItemObject) => void
}) => {
  const theme = useTheme()

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: '32px',
        }}
      >
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography component="h2" fontSize="24px" fontWeight="500">
            {category.name}
          </Typography>
          {category.description && (
            <Typography component="h3" fontSize="16px" color="text.secondary">
              {category.description}
            </Typography>
          )}
          <Box
            component="ul"
            sx={{
              display: 'flex',
              justifyContent: 'start',
              flexWrap: 'wrap',
              listStyle: 'none',
              p: 0,
            }}
          >
            {category.items.map((item, i) => {
              const isDone = !!item.value?.url || !!item.isImageDisabled
              return (
                <Box key={i} sx={{ marginRight: '4px' }} component="li">
                  <Chip
                    label={
                      isDone ? (
                        <Done
                          sx={{ fontSize: '16px', color: 'common.white' }}
                        />
                      ) : (
                        `${i + 1}`
                      )
                    }
                    sx={{
                      width: '28px',
                      height: '28px',
                      backgroundColor: isDone ? 'success.main' : undefined,
                      fontSize: '12px',
                      fontWeight: '500',
                      '& span': {
                        p: 0,
                        display: 'flex',
                      },
                      '&:hover': {
                        bgcolor: isDone
                          ? alpha(theme.palette.success.main, 0.8)
                          : undefined,
                      },
                    }}
                    onClick={() => {
                      document
                        .querySelector(`#category-item-${i}`)
                        ?.scrollIntoView({ behavior: 'smooth' })
                    }}
                  />
                </Box>
              )
            })}
          </Box>
        </Box>
        <CircularProgressWithLabel
          size={80}
          fontSize="20px"
          value={calculateCompletion(category)}
        />
      </Box>
      {category.items.map((item, i) => {
        let imgContent: JSX.Element
        if (item.value?.url) {
          imgContent = (
            <Box
              sx={{
                flex: 2,
                height: '250px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'grey.100',
                position: 'relative',
              }}
            >
              <Box
                component="img"
                src={item.value?.url}
                sx={{
                  width: 'auto',
                  height: 'auto',
                  maxWidth: '100%',
                  maxHeight: '100%',
                }}
              />
              {updatingItem === item && (
                <LinearProgress
                  sx={{
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    position: 'absolute',
                  }}
                />
              )}
            </Box>
          )
        } else {
          imgContent = (
            <Box
              sx={{
                flex: 2,
                height: '250px',
                backgroundColor: 'grey.100',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              {item.isImageDisabled ? (
                <Typography fontSize="28px" fontWeight="700">
                  N/A
                </Typography>
              ) : (
                <ImageNotSupported sx={{ width: '36px', height: '36px' }} />
              )}
            </Box>
          )
        }

        let buttonStyles: SxProps = {
          padding: '8px 12px',
          fontSize: '14px',
        }

        const onFileChange = (item: ItemObject) => (
          event: React.ChangeEvent<HTMLInputElement>,
        ) => {
          const newFile = event.target.files && event.target.files[0]

          if (!newFile) {
            return
          }

          const fileURL = URL.createObjectURL(newFile)
          item.value = {
            file: newFile,
            localURL: fileURL,
            url: item.value?.url,
          }
          onItemChange(item)
        }

        return (
          <React.Fragment key={i}>
            <Box
              id={`category-item-${i}`}
              sx={{ display: 'flex', flexDirection: 'row' }}
            >
              {imgContent}
              <Box
                sx={{
                  flex: 3,
                  padding: '32px',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                }}
              >
                <Typography fontSize="20px" fontWeight="500">
                  {item.name}
                </Typography>
                {item.description && (
                  <Typography fontSize="14px" color="text.secondary">
                    {item.description}
                  </Typography>
                )}
                <Box sx={{ marginTop: '16px' }}>
                  {item.value?.url && (
                    <>
                      <Box
                        component="label"
                        htmlFor={`replace-image-${i}`}
                        sx={{ mr: '8px' }}
                      >
                        <Box
                          component="input"
                          id={`replace-image-${i}`}
                          accept="image/*"
                          type="file"
                          sx={{ display: 'none' }}
                          onChange={onFileChange(item)}
                        />
                        <Button
                          variant="contained"
                          component="span"
                          disabled={!!updatingItem}
                          sx={buttonStyles}
                        >
                          Replace image
                        </Button>
                      </Box>
                      <Button
                        variant="outlined"
                        component="span"
                        sx={buttonStyles}
                        disabled={!!updatingItem}
                        onClick={() => {
                          delete item.value
                          onItemChange(item)
                        }}
                      >
                        Remove image
                      </Button>
                    </>
                  )}
                  {!item.value?.url && (
                    <Box
                      component="label"
                      htmlFor={`add-image-${i}`}
                      sx={{ mr: '8px' }}
                    >
                      {item.isImageDisabled ? (
                        <Button variant="contained" sx={buttonStyles} disabled>
                          No image needed
                        </Button>
                      ) : (
                        <>
                          <Box
                            component="input"
                            id={`add-image-${i}`}
                            accept="image/*"
                            type="file"
                            sx={{ display: 'none' }}
                            onChange={onFileChange(item)}
                          />
                          <Button
                            variant="contained"
                            component="span"
                            disabled={!!updatingItem}
                            sx={buttonStyles}
                          >
                            Add image
                          </Button>
                        </>
                      )}
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            {i < category.items.length - 1 && (
              <Divider sx={{ margin: '32px 0' }} light />
            )}
          </React.Fragment>
        )
      })}
    </Box>
  )
}

export default ViewReportPage
