import { useTheme } from "@mui/material";
import { Box, BoxProps, css, keyframes, styled } from "@mui/system";

const Animation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const animationMixin = css`
  ${Animation} 1.2s linear infinite;
`;

interface SpinnerProps {
  size: number;
  ringColor: string;
}

const Spinner = styled("div")<SpinnerProps>`
  content: " ";
  display: block;
  width: ${({ size }) => (size * 0.8).toFixed(2)}px;
  height: ${({ size }) => (size * 0.8).toFixed(2)}px;
  margin: ${({ size }) => (size * 0.1).toFixed(2)}px;
  border-radius: 50%;
  border: ${({ size }) => (size * 0.1).toFixed(2)}px solid
    ${({ ringColor: $ringColor }) => $ringColor};
  border-color: ${({ ringColor: $ringColor }) => $ringColor} transparent
    ${({ ringColor: $ringColor }) => $ringColor} transparent;
  animation: ${animationMixin};
`;

interface Props extends BoxProps {
  size?: number;
  ringColor?: string;
}

const LoadingSpinner = ({ size, ringColor, ...boxProps }: Props) => {
  const theme = useTheme();

  return (
    <Box
      {...boxProps}
      component={Spinner}
      size={size ?? 24}
      ringColor={ringColor ?? theme.palette.text.primary}
    />
  );
};

export default LoadingSpinner;
