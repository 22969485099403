import { User } from '@firebase/auth'
import {
  Add,
  ArrowBack,
  Close,
  DeleteSharp,
  Done,
  ExpandLess,
  ExpandMore,
  ExpandMoreSharp,
  FormatListBulletedSharp,
  Save,
  StarSharp,
  TitleSharp,
} from '@mui/icons-material'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Chip,
  Collapse,
  Container,
  Divider,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { Box, styled, SxProps } from '@mui/system'
import { capitalize, isEqual } from 'lodash'
import * as React from 'react'
import { Navigate } from 'react-router'
import { useNavigate, useParams } from 'react-router-dom'
import { fetchReports, selectReportByID } from '../../features/reportSlice'
import { useAppDispatch, useAppSelector } from '../../hooks'
import Label from '../../models/label'
import {
  CategoryObject,
  HeaderObject,
  ItemObject,
  ReportData,
  ReportObject,
} from '../../models/report'
import { observeReportChanges } from '../../store'
import {
  createReport,
  getReportData,
  updateReport,
} from '../../usecases/reportUsecase'
import AppSelect from '../AppSelect'
import AppTextField from '../AppTextField'

const FileInput = styled('input')`
  display: none;
`

const presetData: (currentUser: User) => ReportData = (currentUser) => ({
  ownerID: currentUser.uid,
  header: {
    name: '',
    siteName: '',
    type: 'header',
    information: [],
  },
  categories: [
    {
      name: '',
      type: 'category',
      columnCount: 2,
      items: [
        {
          name: '',
          type: 'item',
        },
      ],
    },
  ],
})

type PublishingStatus = 'idle' | 'publishing' | 'published'
type ReportError = [ReportObject, string, string]

const CreateReportPage = () => {
  const params = useParams()
  const { id: reportID } = params

  const currentUser = useAppSelector((state) => state.users.currentUser)
  const report = useAppSelector((state) =>
    selectReportByID(state, reportID ?? ''),
  )
  const reportsStatus = useAppSelector((state) => state.reports.status)

  const [data, setData] = React.useState(
    reportID ? null : currentUser && presetData(currentUser),
  )
  const [labels, setLabels] = React.useState<Label[]>([])
  const [currentObject, setCurrentObject] = React.useState<ReportObject | null>(
    reportID ? null : data?.header ?? null,
  )
  const [error, setError] = React.useState<ReportError>()
  const [publishingStatus, setPublishingStatus] = React.useState<
    PublishingStatus
  >('idle')
  const [isAddingNewLabel, setIsAddingNewLabel] = React.useState(false)
  const [newLabel, setNewLabel] = React.useState('')

  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  React.useEffect(() => {
    if (!reportID || !currentUser) return

    if (!data) {
      getReportData(reportID).then((reportData) => {
        setData(reportData)
        setCurrentObject(reportData.header)
      })
    }

    switch (reportsStatus) {
      case 'idle':
        dispatch(fetchReports(currentUser.uid))
        break
      case 'fetched':
        const unsubscribes = observeReportChanges(currentUser.uid)
        return () => unsubscribes.forEach((e) => e())
    }
  }, [reportID, reportsStatus, data, currentUser, dispatch])

  React.useEffect(() => {
    if (!report) return

    if (report.labels && report.labels.length) {
      setLabels(report.labels)
    }
  }, [report])

  if (!data || !currentObject) {
    return <></>
  }

  if (publishingStatus === 'published') {
    return <Navigate to="/" />
  }

  const setReportError = <
    Object extends ReportObject,
    Name extends keyof Object
  >(
    object: Object,
    name: Name,
    error: string,
  ) => {
    setError([object, `${name}`, error])
  }

  const commitDataChange = () => {
    setData({ ...data })
  }

  const validateReportData = () => {
    if (!data.header.name) {
      setCurrentObject(data.header)
      setReportError(data.header, 'name', 'Report title must not be empty.')
      return false
    }

    if (!data.header.siteName) {
      setCurrentObject(data.header)
      setReportError(data.header, 'siteName', 'Site name must not be empty.')
      return false
    }

    for (const category of data.categories) {
      if (!category.name) {
        setCurrentObject(category)
        setReportError(category, 'name', 'Name must not be empty.')
        return false
      }

      for (const item of category.items) {
        if (!item.name) {
          setCurrentObject(item)
          setReportError(item, 'name', 'Name must not be empty.')
          return false
        }
      }
    }

    return true
  }

  const addCategory = () => {
    const newCategory: CategoryObject = {
      name: '',
      columnCount: 2,
      type: 'category',
      items: [
        {
          name: '',
          type: 'item',
        },
      ],
    }
    data.categories.push(newCategory)
    setCurrentObject(newCategory)
    commitDataChange()
  }

  const removeCurrentObject = () => {
    switch (currentObject.type) {
      case 'category':
        data.categories = data.categories.filter((e) => e !== currentObject)

        const prevCategory = data.categories[data.categories.length - 1]
        setCurrentObject(prevCategory ? prevCategory : data.header)
        break
      case 'item':
        for (const category of data.categories) {
          let isItemFound = false

          for (const item of category.items) {
            if (item === currentObject) {
              category.items = category.items.filter((e) => e !== item)
              isItemFound = true

              const count = category.items.length - 1
              setCurrentObject(count ? category.items[count - 1] : category)
            }
          }

          if (isItemFound) break
        }
        break
    }

    commitDataChange()
  }

  const createOrUpdateReport = () => {
    if (!currentUser || !validateReportData()) return

    setPublishingStatus('publishing')
    if (reportID) {
      updateReport(currentUser.uid, reportID, labels, data)
        .then(() => setPublishingStatus('published'))
        .catch((error) => {
          console.log(error)
          setPublishingStatus('idle')
        })
    } else {
      createReport(currentUser.uid, labels, data)
        .then(() => setPublishingStatus('published'))
        .catch((error) => {
          console.log(error)
          setPublishingStatus('idle')
        })
    }
  }

  let onPrev,
    onNext: (() => void) | null = null
  let pageTitle: string
  let pageSubtitle: React.ReactNode
  let currentPage: JSX.Element
  let isObjectRequired: boolean

  const onTitleChange = (name: string) => {
    currentObject.name = name
    commitDataChange()

    if (error && error[0] === currentObject && error[1] === 'name') {
      setError(undefined)
    }
  }

  const onSiteNameChange = (siteName: string) => {
    const headerObject = currentObject as HeaderObject
    headerObject.siteName = siteName
    commitDataChange()

    if (error && error[0] === currentObject && error[1] === 'siteName') {
      setError(undefined)
    }
  }

  const commitNewLabel = () => {
    if (!newLabel.length) return

    setLabels([...labels, { name: newLabel }])
    setNewLabel('')
    setIsAddingNewLabel(false)
  }

  switch (currentObject.type) {
    case 'header': {
      pageTitle = "Let's start with the report header."
      pageSubtitle = 'Report Header'
      isObjectRequired = true
      onNext = () => {
        setCurrentObject(data.categories[0])
      }
      currentPage = (
        <HeaderPage
          headerObject={currentObject}
          error={error}
          onTitleChange={onTitleChange}
          onSiteNameChange={onSiteNameChange}
          onLeftLogoChange={(file, fileURL) => {
            currentObject.leftLogo = {
              file: file,
              localURL: fileURL,
              url: currentObject.leftLogo?.url,
            }
            if (!currentObject.leftLogo?.url) {
              delete currentObject.leftLogo?.url
            }
            commitDataChange()
          }}
          onRightLogoChange={(file, fileURL) => {
            currentObject.rightLogo = {
              file: file,
              localURL: fileURL,
              url: currentObject.rightLogo?.url,
            }
            if (!currentObject.rightLogo?.url) {
              delete currentObject.rightLogo?.url
            }
            commitDataChange()
          }}
          onInfoChange={(info) => {
            if (isEqual(currentObject.information, info)) return

            currentObject.information = info
            commitDataChange()
          }}
        />
      )
      break
    }
    case 'category': {
      pageTitle = 'Category'

      const index = data.categories.findIndex((e) => e === currentObject)

      pageSubtitle = `Category ${index + 1}/${data.categories.length}`
      isObjectRequired = data.categories.length === 1
      onPrev = () => {
        const prevCategory = data.categories[index - 1]
        setCurrentObject(
          prevCategory
            ? prevCategory.items[prevCategory.items.length - 1]
            : data.header,
        )
      }
      onNext = () => {
        setCurrentObject(currentObject.items[0])
      }

      const onDescriptionChange = (description: string) => {
        currentObject.description = description
        commitDataChange()
      }

      currentPage = (
        <CategoryPage
          categoryObject={currentObject}
          error={error}
          onNameChange={onTitleChange}
          onDescriptionChange={onDescriptionChange}
        />
      )
      break
    }
    case 'item': {
      pageTitle = 'Category item'

      const categoryIndex = data.categories.findIndex((e) =>
        e.items.includes(currentObject),
      )
      const category = data.categories[categoryIndex]
      const itemIndex = category.items.findIndex((e) => e === currentObject)

      pageSubtitle = (
        <>
          {category.name ? category.name : `Category ${categoryIndex + 1}`}{' '}
          {itemIndex + 1}/{category.items.length}
        </>
      )
      isObjectRequired = category.items.length === 1
      onPrev = () => {
        setCurrentObject(
          itemIndex > 0 ? category.items[itemIndex - 1] : category,
        )
      }
      if (category.items[itemIndex + 1]) {
        onNext = () => {
          setCurrentObject(category.items[itemIndex + 1])
        }
      } else if (data.categories[categoryIndex + 1]) {
        onNext = () => {
          setCurrentObject(data.categories[categoryIndex + 1])
        }
      }

      const onDescriptionChange = (description: string) => {
        currentObject.description = description
        commitDataChange()
      }

      const onDisableImageChange = (disabled: boolean) => {
        currentObject.isImageDisabled = disabled
        commitDataChange()
      }

      currentPage = (
        <ItemPage
          itemObject={currentObject}
          error={error}
          onNameChange={onTitleChange}
          onDescriptionChange={onDescriptionChange}
          onDisableImageChange={onDisableImageChange}
          // onValueChange={(file, fileURL) => {
          //   currentObject.value = {
          //     file: file,
          //     localURL: fileURL,
          //     url: currentObject.value?.url,
          //   }
          //   if (!currentObject.value?.url) {
          //     delete currentObject.value?.url
          //   }
          //   commitDataChange()
          // }}
        />
      )
      break
    }
  }

  return (
    <Container maxWidth="lg">
      <Box sx={{ my: 4 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              component="a"
              href="/"
              sx={{
                mr: '24px',
              }}
            >
              <Box
                component="img"
                src="/logo512.png"
                sx={{
                  width: '48px',
                  borderRadius: '6px',
                  boxShadow: '0px 2px 4px rgba(0,0,0,0.4)',
                }}
                onClick={() => {
                  navigate('/')
                }}
              />
            </Box>
            <Typography
              component="h1"
              fontSize="20px"
              fontWeight="400"
              sx={{ flex: 1, margin: '36px 0', textAlign: 'start' }}
            >
              {reportID ? 'Edit Report' : 'Create Report'}
            </Typography>
            <Button
              variant="contained"
              endIcon={<Save sx={{ ml: '16px' }} />}
              onClick={createOrUpdateReport}
            >
              Save
            </Button>
          </Box>
          <Stack direction="row" spacing={1} sx={{ pb: 4 }}>
            {labels.map((label, i) => (
              <Chip
                label={`#${label.name}`}
                color="secondary"
                key={i}
                size="small"
                onDelete={() => setLabels(labels.filter((e) => e !== label))}
              />
            ))}
            {isAddingNewLabel ? (
              <TextField
                placeholder="New tag"
                variant="outlined"
                size="small"
                value={newLabel}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    commitNewLabel()
                  }
                }}
                onChange={(e) => {
                  const val = e.target.value
                    .replaceAll(/[^A-Za-z0-9]/g, '')
                    .toLowerCase()

                  if (val === newLabel) return

                  setNewLabel(val)
                }}
                sx={{
                  '& .MuiInputBase-root': {
                    height: 24,
                  },
                  '& input': {
                    px: 1,
                    py: 0,
                    fontSize: '0.8125rem',
                  },
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      sx={{ '& svg': { fontSize: '1rem' } }}
                    >
                      <IconButton
                        onClick={() => {
                          setNewLabel('')
                          setIsAddingNewLabel(false)
                        }}
                        color="error"
                        edge="end"
                        disableRipple
                      >
                        <Close />
                      </IconButton>
                      <IconButton
                        onClick={commitNewLabel}
                        color="success"
                        edge="end"
                        disableRipple
                      >
                        <Done />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <Chip
                label="Add tag"
                icon={<Add />}
                size="small"
                variant="outlined"
                onClick={() => setIsAddingNewLabel(true)}
              />
            )}
          </Stack>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Box
              sx={{
                flex: 1,
                borderRight: '1px solid',
                borderRightColor: 'divider',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                overflow: 'hidden',
              }}
            >
              <ObjectList
                data={data}
                selectedObject={currentObject}
                onSelect={(object) => setCurrentObject(object)}
                onMove={(object, direction) => {
                  switch (object.type) {
                    case 'category': {
                      const index = data.categories.findIndex(
                        (e) => e === object,
                      )
                      switch (direction) {
                        case 'up':
                          if (index === 0) return
                          data.categories.splice(index, 1)
                          data.categories.splice(index - 1, 0, object)
                          break
                        case 'down':
                          if (index === data.categories.length - 1) return
                          data.categories.splice(index, 1)
                          data.categories.splice(index + 1, 0, object)
                          break
                      }
                      break
                    }
                    case 'item': {
                      const category = data.categories.find((e) =>
                        e.items.includes(object),
                      )
                      if (!category) return

                      const index = category.items.findIndex(
                        (e) => e === object,
                      )
                      switch (direction) {
                        case 'up':
                          if (index === 0) return
                          category.items.splice(index, 1)
                          category.items.splice(index - 1, 0, object)
                          break
                        case 'down':
                          if (index === category.items.length - 1) return
                          category.items.splice(index, 1)
                          category.items.splice(index + 1, 0, object)
                          break
                      }
                      break
                    }
                  }

                  commitDataChange()
                }}
                onNewItem={(category) => {
                  const newItem: ItemObject = { name: '', type: 'item' }
                  category.items.push(newItem)
                  commitDataChange()
                  setCurrentObject(newItem)
                }}
              />
              <Button variant="contained" sx={{ mt: 6 }} onClick={addCategory}>
                Add category
              </Button>
            </Box>
            <Box
              sx={{
                flex: 2,
                display: 'flex',
                flexDirection: 'column',
                padding: '36px 0 36px 36px',
              }}
            >
              <Typography
                variant="subtitle1"
                color="text.secondary"
                fontSize="14px"
              >
                {pageSubtitle}
              </Typography>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                    lineHeight: '2em',
                    flex: 1,
                  }}
                >
                  {pageTitle}
                </Typography>
                <IconButton
                  disabled={isObjectRequired}
                  onClick={removeCurrentObject}
                >
                  <DeleteSharp />
                </IconButton>
              </Box>
              {currentPage}
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'end',
                  mt: 6,
                }}
              >
                {onPrev ? (
                  <IconButton sx={{ mr: '16px' }} onClick={onPrev}>
                    <ArrowBack />
                  </IconButton>
                ) : (
                  <></>
                )}
                {onNext ? (
                  <Button variant="contained" onClick={onNext}>
                    Next
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    onClick={createOrUpdateReport}
                    disabled={publishingStatus === 'publishing'}
                  >
                    Done
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

const ObjectList = ({
  data,
  selectedObject,
  onSelect,
  onMove,
  onNewItem,
}: {
  data: ReportData
  selectedObject: ReportObject
  onSelect: (object: ReportObject) => void
  onMove: (object: ReportObject, direction: 'up' | 'down') => void
  onNewItem: (category: CategoryObject) => void
}) => {
  const [openCategory, setOpenCategory] = React.useState<CategoryObject>()

  React.useEffect(() => {
    if (
      selectedObject.type === 'item' &&
      !openCategory?.items.includes(selectedObject)
    ) {
      setOpenCategory(
        data.categories.find((e) => e.items.includes(selectedObject)),
      )
    }
  }, [data, openCategory, selectedObject])

  const { header, categories } = data

  const avatarStyles: SxProps = {
    borderRadius: '24px',
    backgroundColor: 'secondary.main',
    p: '12px',
    boxSizing: 'content-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }

  const toggleExpandCategory = (category: CategoryObject) => () => {
    const shouldClose = openCategory === category
    setOpenCategory(shouldClose ? undefined : category)

    if (
      shouldClose &&
      selectedObject.type === 'item' &&
      category.items.includes(selectedObject)
    ) {
      onSelect(category)
    }
  }

  const ObjectNavigator = ({ object }: { object: ReportObject }) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginRight: '16px',
      }}
    >
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation()
          onMove(object, 'up')
        }}
        sx={{
          minWidth: 0,
          p: '2px 4px',
          backgroundColor: 'grey.300',
          '&:hover': {
            backgroundColor: 'grey.400',
          },
        }}
      >
        <ExpandLess sx={{ fontSize: '1rem', color: 'grey.600' }} />
      </Button>
      <Button
        variant="contained"
        onClick={(e) => {
          e.stopPropagation()
          onMove(object, 'down')
        }}
        sx={{
          minWidth: 0,
          p: '2px 4px',
          backgroundColor: 'grey.300',
          mt: '2px',
          '&:hover': {
            backgroundColor: 'grey.400',
          },
        }}
      >
        <ExpandMore sx={{ fontSize: '1rem', color: 'grey.600' }} />
      </Button>
    </Box>
  )

  return (
    <List
      sx={{
        width: '100%',
        '& .MuiListItemButton-root': { overflow: 'hidden' },
        '& .MuiListItemIcon-root': { minWidth: '64px' },
        '& .MuiListItemText-secondary': {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      }}
    >
      <ListItem disablePadding>
        <ListItemButton
          selected={selectedObject === header}
          onClick={() => onSelect(header)}
        >
          <ListItemIcon>
            <TitleSharp sx={avatarStyles} />
          </ListItemIcon>
          <ListItemText primary="Header" secondary={header.name} />
        </ListItemButton>
      </ListItem>
      {categories.map((category, i) => (
        <React.Fragment key={i}>
          <ListItem
            secondaryAction={
              <IconButton edge="end" onClick={toggleExpandCategory(category)}>
                {openCategory === category ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            }
            disablePadding
          >
            <ListItemButton
              selected={selectedObject === category}
              onClick={() => onSelect(category)}
            >
              <ObjectNavigator object={category} />
              <ListItemIcon>
                <StarSharp sx={avatarStyles} />
              </ListItemIcon>
              <ListItemText
                primary={capitalize(category.type)}
                secondary={category.name}
              />
            </ListItemButton>
          </ListItem>
          <Collapse in={openCategory === category} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {category.items.map((item, j) => (
                <ListItemButton
                  key={j}
                  sx={{ pl: 4 }}
                  selected={selectedObject === item}
                  onClick={() => onSelect(item)}
                >
                  <ObjectNavigator object={item} />
                  <ListItemIcon>
                    <FormatListBulletedSharp sx={avatarStyles} />
                  </ListItemIcon>
                  <ListItemText
                    primary={capitalize(item.type)}
                    secondary={item.name}
                  />
                </ListItemButton>
              ))}
              <ListItemButton
                sx={{ pl: 4 }}
                onClick={() => onNewItem(category)}
              >
                <ListItemIcon>
                  <Add sx={{ p: '12px', boxSizing: 'content-box' }} />
                </ListItemIcon>
                <ListItemText primary="New item" />
              </ListItemButton>
            </List>
          </Collapse>
        </React.Fragment>
      ))}
    </List>
  )
}

const HeaderPage = ({
  headerObject,
  error,
  onTitleChange,
  onSiteNameChange,
  onLeftLogoChange,
  onRightLogoChange,
  onInfoChange,
}: {
  headerObject: HeaderObject
  error?: ReportError
  onTitleChange: (name: string) => void
  onSiteNameChange: (name: string) => void
  onLeftLogoChange: (file: File, fileURL: string) => void
  onRightLogoChange: (file: File, fileURL: string) => void
  onInfoChange: (info: [string, string][]) => void
}) => {
  const [name, setName] = React.useState(headerObject.name)
  const [siteName, setSiteName] = React.useState(headerObject.siteName)
  const [leftLogoURL, setLeftLogoURL] = React.useState(
    headerObject.leftLogo?.localURL ?? headerObject.leftLogo?.url,
  )
  const [rightLogoURL, setRightLogoURL] = React.useState(
    headerObject.rightLogo?.localURL ?? headerObject.rightLogo?.url,
  )
  const [info, setInfo] = React.useState(headerObject.information)

  const leftLogoFileInputRef = React.useRef<HTMLInputElement>(null)
  const rightLogoFileInputRef = React.useRef<HTMLInputElement>(null)

  const headerError = error && error[0] === headerObject ? error : undefined

  React.useEffect(() => {
    setName(headerObject.name)
    setRightLogoURL(headerObject.rightLogo?.url)
    setInfo(headerObject.information)
  }, [headerObject])

  React.useEffect(() => {
    onInfoChange(info)
  }, [info, onInfoChange])

  const handleLeftLogoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newFile = event.target.files && event.target.files[0]

    if (!newFile) {
      return
    }

    const fileURL = URL.createObjectURL(newFile)
    setLeftLogoURL(fileURL)
    onLeftLogoChange(newFile, fileURL)
  }

  const handleRightLogoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newFile = event.target.files && event.target.files[0]

    if (!newFile) {
      return
    }

    const fileURL = URL.createObjectURL(newFile)
    setRightLogoURL(fileURL)
    onRightLogoChange(newFile, fileURL)
  }

  const accordionTitle = (which: 'leftLogo' | 'rightLogo') => {
    switch (which) {
      case 'leftLogo': {
        if (headerObject.leftLogo?.file)
          return headerObject.leftLogo?.file?.name
        if (!leftLogoURL) return <></>

        const url = new URL(unescape(leftLogoURL))
        return (
          <Typography>
            <strong>Left logo:</strong> {url.pathname.split('/').pop()}
          </Typography>
        )
      }
      case 'rightLogo': {
        if (headerObject.rightLogo?.file)
          return headerObject.rightLogo?.file?.name
        if (!rightLogoURL) return <></>

        const url = new URL(unescape(rightLogoURL))
        return (
          <Typography>
            <strong>Right logo:</strong> {url.pathname.split('/').pop()}
          </Typography>
        )
      }
    }
  }

  return (
    <>
      <Divider sx={{ margin: '32px 0', opacity: 0.6 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
        }}
      >
        <AppTextField
          error={!!(headerError && headerError[1] === 'name')}
          label="Report Title"
          placeholder="Progress Report"
          value={name}
          helperText={
            headerError && headerError[1] === 'name' && headerError[2]
          }
          onChange={(e) => {
            setName(e.target.value)
            onTitleChange(e.target.value)
          }}
          sx={{ flex: 1, mr: '16px' }}
        />
        <Button
          variant="contained"
          sx={{ mr: '16px' }}
          onClick={() => {
            leftLogoFileInputRef.current?.click()
          }}
        >
          {leftLogoURL ? 'Replace  left logo' : 'Add left logo'}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            rightLogoFileInputRef.current?.click()
          }}
        >
          {rightLogoURL ? 'Replace right logo' : 'Add right logo'}
        </Button>
      </Box>
      <FileInput
        type="file"
        accept="image/*"
        name="image"
        ref={leftLogoFileInputRef}
        onChange={handleLeftLogoFileChange}
      />
      <FileInput
        type="file"
        accept="image/*"
        name="image"
        ref={rightLogoFileInputRef}
        onChange={handleRightLogoFileChange}
      />
      {leftLogoURL ? (
        <Accordion sx={{ marginTop: '16px' }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            {accordionTitle('leftLogo')}
          </AccordionSummary>
          <AccordionDetails>
            <Box component="img" sx={{ width: '100%' }} src={leftLogoURL} />
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      {rightLogoURL ? (
        <Accordion sx={{ marginTop: '16px' }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            {accordionTitle('rightLogo')}
          </AccordionSummary>
          <AccordionDetails>
            <Box component="img" sx={{ width: '100%' }} src={rightLogoURL} />
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
      <AppTextField
        error={!!headerError && headerError[1] === 'siteName'}
        label="Site Name"
        value={siteName}
        helperText={
          headerError && headerError[1] === 'siteName' && headerError[2]
        }
        placeholder="Centratama - Jombang"
        onChange={(e) => {
          setSiteName(e.target.value)
          onSiteNameChange(e.target.value)
        }}
        sx={{ m: '24px 0' }}
      />
      {info?.map((e, i) => (
        <React.Fragment key={i}>
          <Divider sx={{ margin: '24px 0' }} />
          <Typography sx={{ marginBottom: '16px' }}>Info #{i + 1}</Typography>
          <AppTextField
            label="Name"
            placeholder="Example: Alamat Site"
            defaultValue={e[0]}
            onChange={(e) => {
              const newInfo = [...info]
              newInfo[i][0] = e.target.value
              setInfo(newInfo)
            }}
          />
          <AppTextField
            label="Value"
            placeholder="Example: Jl. Pesangrahan 5 No. 134A"
            defaultValue={e[1]}
            sx={{ margin: '16px 0' }}
            onChange={(e) => {
              const newInfo = [...info]
              newInfo[i][1] = e.target.value
              setInfo(newInfo)
            }}
          />
          <Button
            variant="contained"
            onClick={() => {
              const newInfo = [...info]
              newInfo.splice(i, 1)
              setInfo(newInfo)
            }}
          >
            Remove
          </Button>
        </React.Fragment>
      ))}
      <Divider sx={{ margin: '24px 0' }} />
      <Button
        variant="outlined"
        onClick={() => {
          setInfo(info ? [...info, ['', '']] : [['', '']])
        }}
      >
        Add project info
      </Button>
    </>
  )
}

const CategoryPage = ({
  categoryObject,
  error,
  onNameChange,
  onDescriptionChange,
}: {
  categoryObject: CategoryObject
  error?: ReportError
  onNameChange: (name: string) => void
  onDescriptionChange: (description: string) => void
}) => {
  const [name, setName] = React.useState(categoryObject.name)
  const [description, setDescription] = React.useState(
    categoryObject.description,
  )
  const [columnCount, setColumnCount] = React.useState(
    categoryObject.columnCount,
  )

  const categoryError = error && error[0] === categoryObject ? error : undefined

  React.useEffect(() => {
    setName(categoryObject.name)
    setDescription(categoryObject.description ?? '')
    setColumnCount(categoryObject.columnCount)
  }, [categoryObject])

  return (
    <>
      <Divider sx={{ margin: '32px 0', opacity: 0.6 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <AppTextField
          error={!!categoryError}
          label="Name"
          placeholder="Example: Bowplank"
          helperText={categoryError && categoryError[2]}
          value={name}
          onChange={(e) => {
            setName(e.target.value)
            onNameChange(e.target.value)
          }}
          sx={{ flex: 1, marginRight: '16px' }}
        />
        <AppSelect
          value={columnCount}
          onChange={(e) => {
            setColumnCount(Number(e.target.value))
            categoryObject.columnCount = Number(e.target.value)
          }}
          label="Column"
        >
          <MenuItem value={1}>One (1)</MenuItem>
          <MenuItem value={2}>Two (2)</MenuItem>
          <MenuItem value={3}>Three (3)</MenuItem>
          <MenuItem value={4}>Four (4)</MenuItem>
        </AppSelect>
      </Box>
      <AppTextField
        label="Description"
        placeholder="(Optional)"
        value={description}
        onChange={(e) => {
          setDescription(e.target.value)
          onDescriptionChange(e.target.value)
        }}
        sx={{ flex: 1, mt: '16px' }}
      />
    </>
  )
}

const ItemPage = ({
  itemObject,
  error,
  onNameChange,
  onDescriptionChange,
  onDisableImageChange,
}: // onValueChange,
{
  itemObject: ItemObject
  error?: ReportError
  onNameChange: (name: string) => void
  onDescriptionChange: (name: string) => void
  onDisableImageChange: (disabled: boolean) => void
  // onValueChange: (file: File, fileURL: string) => void
}) => {
  const [name, setName] = React.useState(itemObject.name)
  const [description, setDescription] = React.useState(itemObject.description)
  const [isImageDisabled, setIsImageDisabled] = React.useState(
    !!itemObject.isImageDisabled,
  )
  const [fileURL, setFileURL] = React.useState(
    itemObject.value?.localURL ?? itemObject.value?.url,
  )

  // const fileInputRef = React.useRef<HTMLInputElement>(null)

  const itemError = error && error[0] === itemObject ? error : undefined

  React.useEffect(() => {
    setName(itemObject.name)
    setDescription(itemObject.description ?? '')
    setFileURL(itemObject.value?.url)
    setIsImageDisabled(!!itemObject.isImageDisabled)
  }, [itemObject])

  // const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const newFile = event.target.files && event.target.files[0]

  //   if (!newFile) {
  //     return
  //   }

  //   const fileURL = URL.createObjectURL(newFile)
  //   setFileURL(fileURL)
  //   onValueChange(newFile, fileURL)
  // }

  const accordionTitle = () => {
    if (itemObject.value?.file) return itemObject.value?.file?.name
    if (!fileURL) return ''

    const url = new URL(unescape(fileURL))
    return url.pathname.split('/').pop()
  }

  return (
    <>
      <Divider sx={{ margin: '32px 0', opacity: 0.6 }} />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'end',
        }}
      >
        <AppTextField
          error={!!itemError}
          label="Name"
          placeholder="Example: Checking Site Coordinate"
          helperText={itemError && itemError[2]}
          value={name}
          onChange={(e) => {
            setName(e.target.value)
            onNameChange(e.target.value)
          }}
          sx={{ flex: 1, mb: 2 }}
        />
        {/* <Button
          variant="outlined"
          onClick={() => {
            fileInputRef.current?.click();
          }}
        >
          {fileURL ? "Change file" : "Add file"}
        </Button> */}
        {/* <FileInput
          type="file"
          accept="image/*"
          name="image"
          ref={fileInputRef}
          onChange={handleFileChange}
        /> */}
      </Box>
      <AppTextField
        label="Description"
        placeholder="(Optional) Example: Checking Site Contour Actual"
        value={description}
        sx={{ mb: 2 }}
        onChange={(e) => {
          setDescription(e.target.value)
          onDescriptionChange(e.target.value)
        }}
      />
      <Stack direction="row" alignItems="center" sx={{ width: '100%' }}>
        <Typography fontSize="0.875rem" fontWeight="600" sx={{ flex: 1 }}>
          Has image?
        </Typography>
        <Checkbox
          checked={!isImageDisabled}
          onChange={(e) => {
            setIsImageDisabled(!e.target.checked)
            onDisableImageChange(!e.target.checked)
          }}
        />
      </Stack>
      {fileURL && !isImageDisabled ? (
        <Accordion sx={{ mt: 3 }}>
          <AccordionSummary expandIcon={<ExpandMoreSharp />}>
            <Typography>{accordionTitle()}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Box component="img" sx={{ width: '100%' }} src={fileURL} />
          </AccordionDetails>
        </Accordion>
      ) : (
        <></>
      )}
    </>
  )
}

export default CreateReportPage
