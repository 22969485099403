import {
  get,
  onChildChanged,
  onValue,
  push,
  query,
  ref,
  set,
  update,
} from 'firebase/database'
import { database } from '../firebase'
import Label from '../models/label'
import Report, {
  CategoryObject,
  HeaderObject,
  ItemObject,
  ReportData,
} from '../models/report'

export const observeReportDataChanges = (
  reportID: string,
  callback: (data: Partial<ReportData>) => void,
) => {
  const reportsRef = ref(database, `reports/${reportID}`)

  return onChildChanged(reportsRef, (snapshot) => {
    switch (snapshot.key) {
      case 'header':
        callback({ header: snapshot.val() as HeaderObject })
        break
      case 'categories':
        callback({ categories: snapshot.val() as CategoryObject[] })
        break
    }
  })
}

const calculateCompletion = (data: ReportData) => {
  let itemCount = 0
  let completedItemCount = 0
  data.categories.forEach((category) => {
    itemCount += category.items.filter((item) => !item.isImageDisabled).length
    completedItemCount += category.items.filter((e) => e.value?.url).length
  })

  return Math.round((completedItemCount / itemCount) * 100)
}

interface ReportRepository {
  getNewReportID: () => string | null
  fetchReportData: (id: string) => Promise<ReportData>
  uploadReport: (
    userID: string,
    reportID: string,
    labels: Label[],
    data: ReportData,
  ) => Promise<void>
  uploadReportData: (reportID: string, data: ReportData) => Promise<void>
  // updateReport: (
  //   userID: string,
  //   reportID: string,
  //   labels: Label[] | undefined,
  //   reportData?: ReportData,
  // ) => Promise<void>
  updateReport: (
    userID: string,
    reportID: string,
    changes: Partial<Report>,
    data?: ReportData,
  ) => Promise<void>
  updateItem: (
    reportID: string,
    categoryIndex: number,
    itemIndex: number,
    item: ItemObject,
  ) => Promise<void>
  listReports: (userID: string) => Promise<Report[]>
}

const reportRepository: ReportRepository = {
  getNewReportID: () => {
    const reportsRef = ref(database, 'reports')
    return push(reportsRef).key
  },

  fetchReportData: async (id: string) => {
    const reportsRef = ref(database, `reports/${id}`)
    const snapshot = await get(reportsRef)
    return snapshot.val() as ReportData
  },

  uploadReport: async (
    userID: string,
    reportID: string,
    labels: Label[],
    data: ReportData,
  ) => {
    const reportListsRef = ref(database, `report-lists/${userID}/${reportID}`)

    const report: Report = {
      title: data.header.name,
      completion: calculateCompletion(data),
      createdAt: new Date().toISOString(),
      labels: labels,
    }

    await set(reportListsRef, report)
  },

  uploadReportData: async (reportID: string, data: ReportData) => {
    const reportsRef = ref(database, `reports/${reportID}`)
    await set(reportsRef, data)
  },

  // updateReport: (
  //   userID: string,
  //   reportID: string,
  //   labels: Label[] | undefined,
  //   reportData?: ReportData,
  // ) => {
  //   const updates: { [key: string]: any } = {}
  //   updates[`reports/${reportID}`] = reportData

  //   const report: Partial<Report> = {
  //     title: reportData?.header.name,
  //     completion: reportData && calculateCompletion(reportData),
  //     labels: labels,
  //   }

  //   if (!labels) delete report.labels

  //   updates[`report-lists/${userID}/${reportID}`] = report

  //   return update(ref(database), updates)
  // },

  updateReport: async (
    userID: string,
    reportID: string,
    changes: Partial<Report>,
    data?: ReportData,
  ) => {
    const dataRef = ref(database, `reports/${reportID}`)
    const reportsRef = ref(database, `report-lists/${userID}/${reportID}`)

    if (data) {
      await update(dataRef, data)
    }

    await update(reportsRef, changes)
  },

  updateItem: async (
    reportID: string,
    categoryIndex: number,
    itemIndex: number,
    item: ItemObject,
  ) => {
    const updates: { [key: string]: any } = {}
    const key = `reports/${reportID}/categories/${categoryIndex}/items/${itemIndex}`
    updates[key] = item

    await update(ref(database), updates)
  },

  listReports: (userID: string) => {
    const reportsRef = ref(database, `report-lists/${userID}`)

    return new Promise<Report[]>((resolve) => {
      onValue(
        query(reportsRef),
        (snapshot) => {
          const reports = [] as Report[]
          snapshot.forEach((childSnapshot) => {
            const id = childSnapshot.key
            const report = childSnapshot.val() as Report

            if (!id) return

            report.id = id
            reports.push(report)
          })

          resolve(reports)
        },
        {
          onlyOnce: true,
        },
      )
    })
  },
}

export default reportRepository
