import { configureStore } from '@reduxjs/toolkit'
import {
  onChildAdded,
  onChildChanged,
  onChildRemoved,
  ref,
  Unsubscribe,
} from 'firebase/database'
import reportReducer, {
  appendReport,
  modifyReport,
  removeReport,
} from './features/reportSlice'
import userReducer from './features/userSlice'
import { database } from './firebase'
import Report from './models/report'

const store = configureStore({
  reducer: {
    users: userReducer,
    reports: reportReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['user/initializeUserState', 'user/signInUser'],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        ignoredPaths: ['users.currentUser'],
      },
    }),
})

export default store

export const observeReportChanges = (userID: string) => {
  const reportsRef = ref(database, `report-lists/${userID}`)

  const unsubscribes = [] as Unsubscribe[]
  unsubscribes.push(
    onChildAdded(reportsRef, (data) => {
      const id = data.key
      const report = data.val() as Report

      if (!id) return

      report.id = id
      store.dispatch(appendReport(report))
    }),
  )
  unsubscribes.push(
    onChildChanged(reportsRef, (data) => {
      const id = data.key
      const report = data.val() as Partial<Report>

      if (!id) return

      store.dispatch(modifyReport({ id: id, changes: report }))
    }),
  )
  unsubscribes.push(
    onChildRemoved(reportsRef, (data) => {
      if (!data.key) return
      store.dispatch(removeReport(data.key))
    }),
  )

  return unsubscribes
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type AppStore = typeof store
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
