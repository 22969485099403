import {
  deleteObject,
  getDownloadURL,
  ref,
  uploadBytes,
} from "@firebase/storage";
import { storage } from "../firebase";

interface ImageRepository {
  uploadImage: (path: string, imageFile: File) => Promise<string>;
  removeImage: (imagePath: string) => Promise<void>;
}

const imageRepository: ImageRepository = {
  uploadImage: (path: string, imageFile: File) => {
    const imageRef = ref(storage, path);

    return uploadBytes(imageRef, imageFile).then((snapshot) =>
      getDownloadURL(snapshot.ref)
    );
  },
  removeImage: (imagePath: string) => {
    const imageRef = ref(storage, imagePath);
    return deleteObject(imageRef);
  },
};

export default imageRepository;
